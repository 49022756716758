import styled from 'styled-components';

export const Cards = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.card_background_color};
  border-radius: 10px;
  min-width: 195px;
  height: 110px;
  padding: 16px;
  margin-bottom: 10px;
  transition: 0.3s;

  &:hover {
    box-shadow: 0 0 4px ${props => props.theme.card_shadow_color};
  }
`;

export const CardTitle = styled.h2`
  font-size: 18px;
  margin-bottom: -14px;
  color: ${props => props.theme.card_text_secondary_color};
`;

export const CardContent = styled.h1`
  font-size: 48px;
  margin-bottom: -5px;
  color: ${props => props.theme.card_text_primary_color};
`;

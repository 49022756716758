import { Spin } from 'antd';
import React from 'react';

import { Container, Space } from './styles';

const LazyLoading: React.FC = () => {
  return (
    <Container>
      <Spin>
        <Space />
      </Spin>
    </Container>
  );
};

export default LazyLoading;

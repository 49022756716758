import { combineReducers } from 'redux';

import auth from './auth/reducer';
import agency from './agency/reducer';
import user from './user/reducer';
import balance from './balance/reducer';
import menu from './menu/reducer';
import account from './account/reducer';
import permission from './permission/reducer';
import home from './home/reducer';

const combined_reducers = combineReducers({
  auth,
  agency,
  user,
  balance,
  menu,
  account,
  permission,
  home,
});

export default combined_reducers;

export type ActionReturn = {
  type: any;
  payload: any;
};

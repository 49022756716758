import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px;
  min-height: 100vh;

  ${state =>
    state.theme.login_background_image_url
      ? `background-image: url(${state.theme.login_background_image_url});`
      : `background-color: ${state.theme.login_background_color};`}
`;

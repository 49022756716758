import { Reducer } from 'redux';

import { Account } from '../../../types/Account';
import AccountFee from '../../../types/AccountFee';
import { EnumAuthActions } from '../auth/actions';
import { EnumAccountActions } from './actions';

export type IAccountState = {
  info?: Account;
  fees?: AccountFee[];
};

const initial_state: IAccountState = {
  fees: undefined,
  info: undefined,
};

const account: Reducer<IAccountState> = (state = initial_state, action) => {
  switch (action.type) {
    case EnumAuthActions.SIGN_IN:
      return {
        ...state,
        info: action.payload.account ? action.payload.account : state,
      };

    case EnumAccountActions.SET_ACCOUNT_FEES:
      return {
        ...state,
        fees: action.payload,
      };

    case EnumAuthActions.SIGN_OUT:
      return initial_state;

    default:
      return state;
  }
};

export default account;

import React from 'react';
import { useSelector } from 'react-redux';

import { useMenu } from '../../../hooks/useMenu';
import { IState } from '../../../store';
import TypeAccess from '../../../types/enum/TypeAccess';
import AccountMenu from './AccountMenu';
import AgencyMenu from './AgencyMenu';

const Menu: React.FC = () => {
  const type_access = useSelector<IState, TypeAccess | undefined>(state => state.auth.type_access);
  const { setCurrentUrl } = useMenu();

  const handleMenuItemClick = (url: string) => {
    setCurrentUrl(url);
  };

  if (type_access === TypeAccess.ACCOUNT_HOLDER) {
    return <AccountMenu handleMenuItemClick={handleMenuItemClick} />;
  }

  if (type_access === TypeAccess.AGENCY) {
    return <AgencyMenu handleMenuItemClick={handleMenuItemClick} />;
  }

  return null;
};

export default Menu;

import React from 'react';
import { useDispatch } from 'react-redux';
import { setPageInfo, ISetPageInfo, setCurrentUrl } from '../store/modules/menu/actions';

export interface IUseMenu {
  setPageInfo(data: ISetPageInfo): void;
  setCurrentUrl(url: string): void;
}

export function useMenu(): IUseMenu {
  const dispatch = useDispatch();

  function SetPageInfo(data: ISetPageInfo) {
    dispatch(setPageInfo(data));
  }

  function SetCurrentUrl(data: string) {
    dispatch(setCurrentUrl(data));
  }

  return { setPageInfo: SetPageInfo, setCurrentUrl: SetCurrentUrl };
}

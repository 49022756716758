import styled, { css } from 'styled-components';

type IType = {
  opened: boolean;
};

export const Item = styled.li<IType>`
  margin-bottom: 4px;

  a.menu-item-main {
    padding: 8px 13px;
    border-radius: ${e => (e.opened ? '20px 20px 0 0' : '20px')};
    display: block;
    color: ${props => props.theme.menu_text_color};
    font-weight: bold;
    transition: 0.5s;
    height: 40px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      display: flex;
      align-items: center;

      svg {
        margin-right: 9px;
      }
    }

    ${e =>
      e.opened &&
      css`
        background-color: rgba(255, 255, 255, 0.1);
      `}

    svg {
      width: 24px;
      font-size: 17px;
    }

    &:hover {
      background-color: ${props => props.theme.menu_hover_item_background_color};
    }

    &.selected {
      color: ${props => props.theme.menu_text_selected_color};
      background-color: ${props => props.theme.menu_selected_item_background_color};
    }
  }
`;

export const SSubmenu = styled.ul<IType>`
  list-style: none;

  background-color: rgba(255, 255, 255, 0.05);
  display: ${e => (!e.opened ? 'none' : 'block')};
  border-radius: 0 0 20px 20px;

  li:last-child > a:hover,
  li:last-child > a.selected {
    border-radius: 0 0 20px 20px;
  }

  a {
    padding: 8px 13px;
    height: 40px;
    display: flex;
    align-items: center;
    color: ${props => props.theme.menu_text_color};
    background-size: cover;

    svg {
      margin-right: 8px;
    }

    &:hover {
      background-color: ${props => props.theme.menu_hover_item_background_color};
    }

    &.selected {
      color: ${props => props.theme.menu_text_selected_color};
      background-color: ${props => props.theme.menu_selected_item_background_color};
    }
  }
`;

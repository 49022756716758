export default function UrlQuery(data: Record<string, unknown>): string {
  return Object.entries(data)
    .filter(([, val]) => val !== undefined && val !== '')
    .map(([key, val]) => `${key}=${val}`)
    .join('&');
}

export function getUrlQuery<T = Record<string, unknown>>(string: string): T | any {
  return string.length
    ? string
        .replace('?', '')
        .split('&')
        .reduce((prev, curr) => {
          const curr_param = curr.split('=');

          return { ...prev, [curr_param[0]]: curr_param[1] };
        }, {})
    : {};
}

export function useQueryParams<T = Record<string, string>>(): T {
  return getUrlQuery<Record<string, string>>(decodeURI(window.location.search));
}

export function setQueryParams(values?: Record<string, unknown>): string {
  const old_params = {}; // getUrlQuery(decodeURI(window.location.search));

  const querys = values ? UrlQuery({ ...old_params, ...values }) : undefined;

  let final_url = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;

  if (querys) {
    final_url += `?${querys}`;
  }

  window.history.pushState({ path: final_url }, '', final_url);

  return final_url;
}

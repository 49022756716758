import { Reducer } from 'redux';

import { EnumAuthActions } from '../auth/actions';
import { EnumMenuActions } from './actions';

export type IMenuState = {
  size: 'middle' | 'small' | 'big';
  current_url: string;
  page_title: string;
  page_subtitle?: string;
};

const initial_state: IMenuState = {
  current_url: '/dashboard',
  size: 'middle',
  page_subtitle: 'Dashboard',
  page_title: '',
};

const menu: Reducer<IMenuState> = (state = initial_state, action) => {
  switch (action.type) {
    case EnumMenuActions.SET_PAGE_INFO:
      return {
        ...state,
        page_subtitle: action.payload.subtitle,
        page_title: action.payload.title,
      };

    case EnumMenuActions.SET_CURRENT_URL:
      return {
        ...state,
        current_url: action.payload.current_url,
      };

    case EnumAuthActions.SIGN_OUT:
      return initial_state;

    default:
      return state;
  }
};

export default menu;

import React, { lazy } from 'react';

import DashboardAgency from '../pages/Dashboard/Agency';
import { EnumPermission } from '../types/enum/EnumPermission';
import Route from './Route';

const AgencyRoutes: React.FC = () => {
  return (
    <>
      <Route path="/dashboard" exact component={DashboardAgency} isPrivate />
      <Route
        path="/pre-compliance"
        exact
        component={lazy(() => import('../pages/Compliance'))}
        permission={EnumPermission.MANAGE_PRE_COMPLIANCE}
        isPrivate
      />
      <Route path="/onboard" exact component={lazy(() => import('../pages/Onboard/List'))} permission={EnumPermission.MANAGE_ACCOUNTS} isPrivate />
      <Route
        path="/onboard/:id_onboard"
        exact
        component={lazy(() => import('../pages/Onboard/Info'))}
        permission={EnumPermission.MANAGE_ACCOUNTS}
        isPrivate
      />

      <Route path="/giraclub" exact component={lazy(() => import('../pages/Giraclub/List'))} permission={EnumPermission.VIEW_GIRACLUB} isPrivate />
      <Route
        path="/giraclub/raffle"
        exact
        component={lazy(() => import('../pages/Giraclub/Raffle/List'))}
        permission={EnumPermission.VIEW_GIRACLUB}
        isPrivate
      />

      <Route path="/accounts" exact component={lazy(() => import('../pages/Account/List'))} permission={EnumPermission.MANAGE_ACCOUNTS} isPrivate />
      <Route
        path="/account/:id_account"
        exact
        component={lazy(() => import('../pages/Account/Info'))}
        permission={EnumPermission.MANAGE_ACCOUNTS}
        isPrivate
      />
      <Route
        path="/account-model"
        exact
        component={lazy(() => import('../pages/AccountModel/List'))}
        permission={EnumPermission.MANAGE_MODEL_ACCOUNT}
        isPrivate
      />
      <Route
        path="/account-model/:id_model_account"
        exact
        component={lazy(() => import('../pages/AccountModel/Info'))}
        permission={EnumPermission.MANAGE_MODEL_ACCOUNT}
        isPrivate
      />
      <Route
        path="/user_agency"
        exact
        component={lazy(() => import('../pages/User/UserAgency'))}
        permission={EnumPermission.MANAGE_AGENCY_USERS}
        isPrivate
      />
      <Route
        path="/statements"
        permission={EnumPermission.VIEW_ALL_TRANSACTIONS}
        exact
        component={lazy(() => import('../pages/Statement'))}
        isPrivate
      />

      <Route path="/profile" permission={EnumPermission.MANAGE_PROFILES} exact component={lazy(() => import('../pages/Profile'))} isPrivate />
      <Route
        path="/profile/create"
        exact
        component={lazy(() => import('../pages/Profile/CreateProfile'))}
        permission={EnumPermission.MANAGE_PROFILES}
        isPrivate
      />
      <Route
        path="/profile/update/:id_profile"
        exact
        component={lazy(() => import('../pages/Profile/UpdateProfile'))}
        permission={EnumPermission.MANAGE_PROFILES}
        isPrivate
      />
      <Route path="/credit-analysis" component={lazy(() => import('../pages/CreditAnalysis'))} isPrivate />

      <Route path="/reports" permission={EnumPermission.VIEW_REPORT} exact component={lazy(() => import('../pages/Report'))} isPrivate />

      <Route path="/config" exact component={lazy(() => import('../pages/Config'))} isPrivate />
      <Route
        path="/create-manual-account"
        exact
        component={lazy(() => import('../pages/Account/List/AdminCreateAccount'))}
        permission={EnumPermission.MANAGE_ACCOUNTS}
        isPrivate
      />
      <Route path="/cards" exact component={lazy(() => import('../pages/CardList'))} permission={EnumPermission.VIEW_CARDS_LIST} isPrivate />
    </>
  );
};

export default AgencyRoutes;

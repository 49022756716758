import React, { lazy, useMemo } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Route from './Route';
import { IState } from '../store';

import AgencyRoutes from './agencyRoutes';
import AccountHolderRoutes from './accountHolderRoutes';

import TypeAccess from '../types/enum/TypeAccess';
import Layouts from '../pages/_layouts';

const Routes: React.FC = () => {
  const type_access = useSelector<IState, TypeAccess | undefined>(state => state.auth.type_access);
  const bank_url = useSelector<IState, string | undefined>(state => state.agency.info?.bank_url);

  const mainRoute = useMemo(() => {
    if (type_access === TypeAccess.AGENCY) {
      return <AgencyRoutes />;
    }

    if (type_access === TypeAccess.ACCOUNT_HOLDER) {
      return <AccountHolderRoutes />;
    }

    return null;
  }, [type_access]);

  const isOnboardAndLogin = useMemo(() => {
    // return false;
    return bank_url !== 'https://application.girabank.com.br';
  }, [bank_url]);

  return (
    <Layouts>
      <Switch>
        <Route path="/" exact component={() => <Redirect to="/login" />} />
        {isOnboardAndLogin ? <Route path="/login" exact component={lazy(() => import('../pages/Login'))} /> : null}
        <Route path="/login/admin" exact component={lazy(() => import('../pages/Login'))} />
        <Route path="/payment/:id" exact component={lazy(() => import('../pages/PaymentLink/Payment'))} />

        {isOnboardAndLogin ? (
          <Route path="/onboard/physical" exact component={lazy(() => import('../pages/Onboard/RegistrationPhyscalPerson'))} />
        ) : null}
        {isOnboardAndLogin ? (
          <Route path="/onboard/legal" exact component={lazy(() => import('../pages/Onboard/RegistrationPhyscalPerson'))} />
        ) : null}

        {mainRoute}

        <Route path="*" exact component={() => <Redirect to="/login" />} isPrivate />
      </Switch>
    </Layouts>
  );
};

export default Routes;

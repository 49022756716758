import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

import { IState } from '../store';
import { updateBalance as actionUpdateBalance } from '../store/modules/balance/actions';
import { IBalanceState } from '../store/modules/balance/reducer';
import BalanceService from '../services/Balance';
import { Balance } from '../types/Balance';

export interface IUseBalance {
  loadingBalance: boolean;
  balance: Balance;
  setBalance(data: Balance): void;
  updateBalance(): Promise<Balance | undefined>;
}

export function useBalance(): IUseBalance {
  const dispatch = useDispatch();
  const selected_balance = useSelector<IState, IBalanceState>(state => state.balance);

  const [loadingBalance, setLoadingBalance] = useState<boolean>(false);

  function setBalance(data: Balance): void {
    dispatch(actionUpdateBalance(data));
  }

  async function updateBalance(): Promise<Balance | undefined> {
    setLoadingBalance(true);

    const response = await BalanceService.getBalance();

    setLoadingBalance(false);

    if (response) {
      setBalance(response);
    }

    return response;
  }

  return { balance: selected_balance!, setBalance, updateBalance, loadingBalance };
}

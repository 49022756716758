import { User } from '../../../types/User';
import { ActionReturn } from '../rootReducer';

export enum EnumUserActions {
  SET_USER_DATA = 'SET_USER_DATA',
}

export function setUserData(data: User): ActionReturn {
  return {
    type: EnumUserActions.SET_USER_DATA,
    payload: data,
  };
}

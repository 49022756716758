import { UserOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Menu } from 'antd';
import { dinheiroMask } from 'masks-br';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useBalance } from '../../../hooks/useBalance';
import AuthService from '../../../services/Auth/AuthService';
import { IState } from '../../../store';
import { signOut } from '../../../store/modules/auth/actions';
import { IMenuState } from '../../../store/modules/menu/reducer';
import { IUserState } from '../../../store/modules/user/reducer';
import { Account as IAccount } from '../../../types/Account';
import TypeAccess from '../../../types/enum/TypeAccess';

import { Container, Header as Head, Account, AccountItem, User, Cards, CardUser, CardBalance, UserInfo } from './styles';

const Header: React.FC = () => {
  const header = useSelector<IState, IMenuState>(state => state.menu);
  const user = useSelector<IState, IUserState>(state => state.user);
  const account = useSelector<IState, IAccount | undefined>(state => state.account.info);
  const type_access = useSelector<IState, TypeAccess | undefined>(state => state.auth.type_access);

  const { balance } = useBalance();

  const dispatch = useDispatch();

  async function handleLogout() {
    const logout = await AuthService.logout();

    if (logout) {
      dispatch(signOut());
    }
  }

  const card = useMemo(() => {
    if (type_access === TypeAccess.ACCOUNT_HOLDER) {
      return (
        <>
          <CardBalance to="/transactions">
            <span>Saldo</span>
            <strong>{dinheiroMask(balance?.amount || 0)}</strong>
          </CardBalance>

          <CardUser>
            <Account>
              <AccountItem>
                <strong>Agência</strong>
                <span>{account?.agency_number || ''}</span>
              </AccountItem>
              <AccountItem>
                <strong>Conta</strong>
                <span>{account?.account_number || ''}</span>
              </AccountItem>
            </Account>

            <User>
              <UserInfo>
                <strong>{user?.social_name}</strong>
                {!account?.is_holder ? <span>{account?.holder_name}</span> : null}
              </UserInfo>

              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item key="3" onClick={() => handleLogout()}>
                      Sair
                    </Menu.Item>
                  </Menu>
                }
                placement="bottomRight"
              >
                <Avatar size={50} icon={<UserOutlined />} />
              </Dropdown>
            </User>
          </CardUser>
        </>
      );
    }

    if (type_access === TypeAccess.AGENCY) {
      return (
        <CardUser>
          <User>
            <UserInfo>
              <strong>{user?.social_name}</strong>
            </UserInfo>

            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="3" onClick={() => handleLogout()}>
                    Sair
                  </Menu.Item>
                </Menu>
              }
              placement="bottomRight"
            >
              <Avatar size={50} icon={<UserOutlined />} />
            </Dropdown>
          </User>
        </CardUser>
      );
    }

    return null;
  }, [type_access, account, user, handleLogout]);

  return (
    <Container>
      <Head>
        {header.page_subtitle && <h2>{header.page_subtitle}</h2>}
        <h1>{header.page_title}</h1>
      </Head>

      <Cards>{card}</Cards>
    </Container>
  );
};

export default Header;

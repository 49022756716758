import styled from 'styled-components';

export const Container = styled.ul`
  list-style: none;
  background-color: ${props => props.theme.menu_background_color};
  border-radius: 20px;
  padding: 15px;

  min-height: 300px;
`;

export const Item = styled.li`
  margin-bottom: 4px;

  a {
    padding: 8px 13px;
    border-radius: 20px;
    display: block;
    color: ${props => props.theme.menu_text_color};
    font-weight: bold;
    transition: 0.5s;
    height: 40px;

    display: flex;
    align-items: center;

    svg {
      width: 24px;
      font-size: 17px;

      margin-right: 9px;
    }

    &:hover {
      background-color: ${props => props.theme.menu_hover_item_background_color};
    }

    &.selected {
      color: ${props => props.theme.menu_text_selected_color};
      background-color: ${props => props.theme.menu_selected_item_background_color};
    }
  }
`;

import { Account } from '../../../types/Account';
import TypeAccess from '../../../types/enum/TypeAccess';
import { User } from '../../../types/User';
import { ActionReturn } from '../rootReducer';

export enum EnumAuthActions {
  SIGN_IN = 'SIGN_IN',
  SIGN_OUT = 'SIGN_OUT',
  SET_GLOBAL_LOADING = 'SET_GLOBAL_LOADING',
}

export interface SignInRequest {
  authenticated: boolean;
  success: boolean;
  token: string;
  next_step?: string;
  type_access: TypeAccess;
  user: User;
  account?: Account;
  permissions: number[];
}

export function signIn(data: SignInRequest): ActionReturn {
  return {
    type: EnumAuthActions.SIGN_IN,
    payload: data,
  };
}

export function signOut(): ActionReturn {
  return {
    type: EnumAuthActions.SIGN_OUT,
    payload: {},
  };
}

export function setGlobalLoading(loading: boolean): ActionReturn {
  return {
    type: EnumAuthActions.SET_GLOBAL_LOADING,
    payload: { loading },
  };
}

import { ISetConfiguration } from '../../store/modules/agency/actions';
import { UpdateAgencyConfigurationRequest } from '../../types/Agency';
import { fetchApi, FetchApiI } from '../api';
import { AgencyConfigResponse } from './AgencyTypes';

class AgencyService {
  async getConfiguration(): Promise<AgencyConfigResponse | undefined> {
    const request: FetchApiI = {
      url: '/public/agency',
      method: 'get',
    };
    return fetchApi(request);
  }

  async updateConfiguration(data: UpdateAgencyConfigurationRequest): Promise<ISetConfiguration | undefined> {
    const request: FetchApiI = {
      url: '/agency/configuration',
      method: 'patch',
      data,
    };
    return fetchApi(request);
  }
}

export default new AgencyService();

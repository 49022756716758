enum DeviceSize {
  MOBILE_S = '320px',
  MOBILE_M = '375px',
  MOBILE_L = '425px',
  TABLET = '768px',
  LAPTOP = '1024px',
  LAPTOP_L = '1440px',
  LAPTOP_4K = '2560px',
}

export default DeviceSize;

import React, { Suspense } from 'react';
import { Route as ReactDomRoute, RouteProps as ReactDomRouteProps, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Result } from 'antd';

import { IState } from '../store';
import { useMenu } from '../hooks/useMenu';
import LazyLoading from '../components/LazyLoading';
import { EnumPermission } from '../types/enum/EnumPermission';
import { usePermission } from '../hooks/usePermission';

interface RouteProps extends ReactDomRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
  permission?: EnumPermission;
}

const Route: React.FC<RouteProps> = ({ isPrivate = false, component: Component, permission, ...rest }: RouteProps) => {
  const authenticated = useSelector<IState, boolean>(state => state.auth.authenticated);
  const { setCurrentUrl } = useMenu();
  const { userHasPermission } = usePermission();

  let ComponentFinal = <Component />;

  if (!authenticated && isPrivate) {
    return <Redirect to="/login" />;
  }

  if (authenticated && !isPrivate) {
    const resto = { ...window.location };

    const fields = resto.search.substr(1).split('&') as string[];

    const has_redirect = fields.find(item => item.indexOf('redirect') !== -1);

    if (has_redirect) {
      const redirect_to = has_redirect.split('=')[1];

      setCurrentUrl(redirect_to);

      return <Redirect to={redirect_to} />;
    }

    if (!(rest?.path as string).startsWith('/payment')) {
      return <Redirect to="/dashboard" />;
    }
  }

  if (permission && !userHasPermission(permission)) {
    ComponentFinal = <Result status="403" subTitle="Desculpe, você não tem acesso a essa página!" />;
  }

  return (
    <ReactDomRoute
      {...rest}
      render={() => {
        return <Suspense fallback={<LazyLoading />}>{ComponentFinal}</Suspense>;
      }}
    />
  );
};

export default Route;

import React from 'react';

import { Container } from './styles';

type ILoader = {
  loading: boolean;
};

const Loader: React.FC<ILoader> = ({ loading }: ILoader) => {
  if (!loading) {
    return null;
  }

  return (
    <Container>
      <span>
        <div className="loader" />
      </span>
    </Container>
  );
};

export default Loader;

import { Spin } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import { numbersMask } from '../../../../utils/masks/numbers';

import { Cards, CardTitle, CardContent } from './styles';

type IProps = {
  loading: boolean;
  title: string;
  content: any;
  to: string;
};

const DashboardAgencyCard: React.FC<IProps> = ({ loading, title, content, to }: IProps) => {
  return (
    <Spin spinning={loading}>
      <Link to={to}>
        <Cards>
          <CardTitle>{title}</CardTitle>
          <CardContent>
            {' '}
            <CountUp start={0} end={content} duration={0.5} formattingFn={content => numbersMask(content) || '0'} />
          </CardContent>
        </Cards>
      </Link>
    </Spin>
  );
};

export default DashboardAgencyCard;

import { createGlobalStyle } from 'styled-components';
import { lighten } from 'polished';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;

    font-family: 'Nunito', 'Roboto', serif;
  }

  html, body {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    font-family: sans-serif;
    height: 100%;
  }

  /* h1, h2, h3, h4, h5, h6, p {
    font-family: 'Nunito', 'Roboto', serif;
  } */

  .ant-table-summary td {
    background: #fafafa;
  }

  .ant-form-item {
    margin-bottom: 8px;
  }

  .ant-btn {
    &:hover, &:focus {
      color: ${props => lighten(0.1, props.theme.primary_color || '#fff')};
      border-color: ${props => lighten(0.1, props.theme.primary_color || '#fff')};
    }
  }

  .ant-picker {
    width: 100%;
  }

  .ant-btn-primary {
    color: #fff;
    background: ${props => props.theme.primary_color};
    border-color: ${props => props.theme.primary_color};

    &:focus {
      color: #fff;
      background: ${props => lighten(0.1, props.theme.primary_color || '#fff')};
      border-color: ${props => lighten(0.1, props.theme.primary_color || '#fff')};
    }

    &:hover {
      color: #fff;
      background: ${props => lighten(0.1, props.theme.primary_color || '#fff')};
      border-color: ${props => lighten(0.1, props.theme.primary_color || '#fff')};
    }
  }

  // Notification Icon
  .anticon.ant-notification-notice-icon-info, .ant-btn-link {
    color: ${props => props.theme.primary_color};
  }

  /* .ant-select:hover, .ant-input:hover, .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: ${props => props.theme.primary_color};
  }
  .ant-input:focus,
  .ant-input-focused,
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    border-color: ${props => props.theme.primary_color};
    box-shadow: 0 0 0 2px ${props => `${props.theme.primary_color}33`};
  } */

  // Menu (Account Info, Account Model Info)
  .ant-menu-item-selected,
  .ant-menu-item-active,
  .ant-menu-submenu-selected,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after,
  .ant-menu-item-active::after,
  .ant-menu-item-selected::after {
    color: ${props => props.theme.primary_color} !important;
    border-color: ${props => props.theme.primary_color} !important;
  }

  // Ant Radio Button
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
  .ant-radio-button-wrapper:hover,
  .ant-radio-button-wrapper:focus {
    color: ${props => props.theme.primary_color} !important;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
  .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-color: ${props => props.theme.primary_color} !important;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: ${props => props.theme.primary_color} !important;
  }

  // Ant date picker
  .ant-picker:hover, .ant-picker-focused {
    border-color: ${props => props.theme.primary_color} !important;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
    border-color: ${props => props.theme.primary_color} !important;
  }
  .ant-picker-range .ant-picker-active-bar, .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: ${props => props.theme.primary_color} !important;
  }

  .inspect {
    border: solid 1px red;
  }
`;
export default GlobalStyle;

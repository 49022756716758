import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { IState } from '../store';
import { EnumFeatures } from '../types/enum/EnumFeatures';

export interface IUseFeature {
  agency_features: Features;
  agencyHasFeature(perm: EnumFeatures): boolean;
}

type FeaturesKeys = keyof typeof EnumFeatures;
type Features = {
  [Property in FeaturesKeys]: boolean;
};

export function useFeature(): IUseFeature {
  const redux_features = useSelector<IState, EnumFeatures[] | undefined>(state => state.agency.features);

  const agency_features = useMemo(() => {
    const final_permission: Features = {} as Features;
    const permissoes_copy = [...(redux_features || [])];

    Object.entries(EnumFeatures).forEach(([key, val]) => {
      if (Number.isNaN(Number(key))) {
        final_permission[key as FeaturesKeys] = permissoes_copy.some(perm => perm === val);
      }
    });

    return final_permission;
  }, [redux_features]);

  function agencyHasFeature(perm: EnumFeatures): boolean {
    return !!redux_features?.find(item => item === perm);
  }

  return { agency_features, agencyHasFeature };
}

import { Balance } from '../../../types/Balance';
import { ActionReturn } from '../rootReducer';

export enum EnumBalanceActions {
  UPDATE_BALANCE = 'UPDATE_BALANCE',
}

export function updateBalance(data: Balance): ActionReturn {
  return {
    type: EnumBalanceActions.UPDATE_BALANCE,
    payload: data,
  };
}

import { ActionReturn } from '../rootReducer';

export enum EnumMenuActions {
  SET_PAGE_INFO = 'SET_PAGE_INFO',
  SET_CURRENT_URL = 'SET_CURRENT_URL',
}

export type ISetPageInfo = {
  title: string;
  subtitle?: string;
};

export function setPageInfo(data: ISetPageInfo): ActionReturn {
  return {
    type: EnumMenuActions.SET_PAGE_INFO,
    payload: data,
  };
}

export function setCurrentUrl(url: string): ActionReturn {
  return {
    type: EnumMenuActions.SET_CURRENT_URL,
    payload: { current_url: url },
  };
}

import { v5 as uuidv5 } from 'uuid';
import * as Bowser from 'bowser';
import {
  AccountAuthenticationRequest,
  AccountAuthenticationResponse,
  AgencyAuthenticationResponse,
  AgencyAuthenticationRequest,
  IVerify2FA,
  ISend2FA,
  ISend2FAResponse,
  IPasswordRecovery,
  IPasswordRecoveryResponse,
  IPasswordRecoveryChangePassword,
  IPasswordRecoveryChangePasswordResponse,
  IPasswordAgencyRecovery,
  IAgencyPasswordRecoveryChangePassword,
  IAgencyPasswordRecoveryChangePasswordResponse,
} from './AuthTypes';
import { fetchApi } from '../api';
import environment from '../../config/environment';

class AuthService {
  async accountAuthentication(data: AccountAuthenticationRequest): Promise<AccountAuthenticationResponse> {
    const bowser = Bowser.getParser(window.navigator.userAgent);
    const response = await fetchApi({
      url: '/auth/account/document',
      method: 'post',
      data: {
        ...data,
        access_from: 'web',
        device: {
          device_brand: bowser.getPlatform().vendor || '',
          device_id: uuidv5(navigator.userAgent, '1b671a64-40d5-491e-99b0-da01ff1f3341'),
          device_name: bowser.getBrowserName(),
          device_model: bowser.getBrowserName(),
          device_platform: 'desktop',
        },
      },
      messages: {
        error: 'Erro ao realizar login!',
      },
    });

    if (!response) {
      return {
        authenticated: false,
      };
    }

    return {
      success: response.success,
      token: response?.token,
      next_step: response.next_step,
      account: response.account,
      user: response.user,
      permissions: response.permissions,
      showMessage: response.showMessage,
      authenticated: true,
    };
  }

  async agencyAuthentication(data: AgencyAuthenticationRequest): Promise<AgencyAuthenticationResponse> {
    const response = await fetchApi({
      url: '/auth/agency',
      method: 'post',
      data,
      messages: {
        error: 'Erro ao realizar login!',
      },
    });

    if (!response) {
      return {
        authenticated: false,
      };
    }

    return {
      token: response?.token,
      authenticated: true,
      user: response.user,
    };
  }

  async registerSendPin(phone: string): Promise<any | undefined> {
    return fetchApi({
      url: '/auth/account/device',
      method: 'post',
      data: {
        phone,
      },
      messages: {
        success: 'Pin enviado com sucesso!',
        error: 'Erro ao enviar Pin, tente novamente!',
        loading: 'Enviando Pin, aguarde...',
      },
    });
  }

  async registerVerifyPin(phone: string, pin: string): Promise<any | undefined> {
    return fetchApi({
      url: `/auth/account/2fa/${pin}`,
      method: 'post',
      data: {
        phone,
      },
      messages: {
        success: 'Pin verificado com sucesso!',
        error: 'Erro ao verificar Pin, tente novamente!',
        loading: 'Verificando Pin, aguarde...',
      },
    });
  }

  async send2FA({ token }: ISend2FA): Promise<ISend2FAResponse | undefined> {
    const authorization = `Bearer ${token}`;

    return fetchApi({
      url: `/auth/account/device`,
      method: 'post',
      headers: {
        authorization,
      },
      messages: {
        error: 'Erro ao enviar código de autenticação, tente novamente!',
        loading: 'Enviando código de autenticação, aguarde...',
        success: 'Código de autenticação enviado com sucesso',
      },
    });
  }

  async send2FAAgency({ token }: ISend2FA): Promise<ISend2FAResponse | undefined> {
    const authorization = `Bearer ${token}`;

    return fetchApi({
      url: `/auth/2fa`,
      method: 'post',
      headers: {
        authorization,
      },
      messages: {
        error: 'Erro ao enviar código de autenticação, tente novamente!',
        loading: 'Enviando código de autenticação, aguarde...',
        success: 'Código de autenticação enviado com sucesso',
      },
    });
  }

  async verify2FA({ pin, token }: IVerify2FA): Promise<any> {
    const authorization = `Bearer ${token}`;

    const response = await fetchApi({
      url: `/auth/account/device/${pin}`,
      method: 'post',
      headers: {
        authorization,
      },
      messages: {
        error: 'Código de autenticação inválido, tente novamente!',
      },
    });

    if (!response) {
      return {
        authenticated: false,
      };
    }

    return {
      token: response?.token,
      authenticated: true,
      user: response.user,
      account: response.account,
      permissions: response.permissions,
    };
  }

  async verify2FAAgency({ pin, token }: IVerify2FA): Promise<any> {
    const authorization = `Bearer ${token}`;

    const response = await fetchApi({
      url: `/auth/2fa/${pin}`,
      method: 'post',
      headers: {
        authorization,
      },
      messages: {
        error: 'Código de autenticação inválido, tente novamente!',
      },
    });

    if (!response) {
      return {
        authenticated: false,
      };
    }

    return {
      token: response?.token,
      authenticated: true,
      user: response.user,
      account: response.account,
      permissions: response.permissions,
    };
  }

  async requestPasswordRecovery({ document }: IPasswordRecovery): Promise<IPasswordRecoveryResponse | undefined> {
    return fetchApi({
      url: `/auth/document/password-recovery`,
      method: 'post',
      data: {
        document,
      },
      messages: {
        error: 'Erro ao enviar código de autenticação, tente novamente!',
        loading: 'Enviando código de autenticação, aguarde...',
        success: 'Código de autenticação enviado com sucesso!',
      },
    });
  }

  async requestAgencyPasswordRecovery({ type, email, agency, account }: IPasswordAgencyRecovery): Promise<IPasswordRecoveryResponse | undefined> {
    return fetchApi({
      url: `/auth/${type}/password-recovery`,
      method: 'post',
      data: {
        email,
        agency,
        account,
        schema: environment.schema,
      },
      messages: {
        error: 'Erro ao enviar código de autenticação, tente novamente!',
        loading: 'Enviando código de autenticação, aguarde...',
        success: 'Código de autenticação enviado com sucesso!',
      },
    });
  }

  async passwordRecoveryChangePassword({
    document,
    password,
    password_confirmation,
    pin,
  }: IPasswordRecoveryChangePassword): Promise<IPasswordRecoveryChangePasswordResponse | undefined> {
    return fetchApi({
      url: `/auth/document/password-recovery/${pin}`,
      method: 'post',
      data: {
        document,
        password,
        password_confirmation,
      },
      messages: {
        error: 'Erro ao alterar senha!',
        loading: 'Alterando senha...',
        success: 'Senha alterada com sucesso!',
      },
    });
  }

  async passwordAgencyRecoveryChangePassword({
    type,
    password,
    password_confirmation,
    pin,
  }: IAgencyPasswordRecoveryChangePassword): Promise<IAgencyPasswordRecoveryChangePasswordResponse | undefined> {
    return fetchApi({
      url: `/auth/${type}/password-recovery/change-password`,
      method: 'post',
      data: {
        password,
        password_confirmation,
        pin,
        schema: environment.schema,
      },
      messages: {
        error: 'Erro ao alterar senha!',
        loading: 'Alterando senha...',
        success: 'Senha alterada com sucesso!',
      },
    });
  }

  async logout(): Promise<boolean> {
    const response = await fetchApi({
      url: '/auth/logout',
      method: 'post',
      messages: {
        error: 'Erro ao realizar logout!',
      },
    });

    return !!response;
  }
}

export default new AuthService();

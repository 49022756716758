import React from 'react';
import { useSelector } from 'react-redux';

import { IState } from '../../../store';

import { Container } from './styled';

const Image: React.FC = () => {
  const configuration = useSelector<IState, string>(state => state?.agency?.configuration?.main_brand_image_url || '');

  return (
    <Container>
      <img height="auto" src={configuration} alt="logo" />
    </Container>
  );
};

export default Image;

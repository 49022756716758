export enum EnumFeatures {
  PIX = 'PIX',
  CARDS = 'CARDS',
  CARD_PHYSICAL = 'CARD_PHYSICAL',
  CARD_VIRTUAL = 'CARD_VIRTUAL',
  CARD_POS = 'CARD_POS',
  CREDIT_CARD_ANALYSIS = 'CREDIT_CARD_ANALISYS',
  TRANSFERS = 'TRANSFERS',
  PAYMENTS = 'PAYMENTS',
  BANKSLIP = 'BANKSLIP',
  // PAYMENT_LINK = 'PAYMENT_LINK',
  USE_DIGITAL_SERVICES = 'USE_DIGITAL_SERVICES',
  MULTIPLE_USER_ACCOUNT = 'MULTIPLE_USER_ACCOUNT',
  GIRACLUB = 'GIRACLUB',
}

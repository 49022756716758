import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { IState } from '../store';
import { AgencyConfiguration } from '../types/Agency';

type Props = {
  children: ReactElement<any>;
};

const Theme: React.FC<Props> = ({ children }: Props) => {
  const configuration = useSelector<IState, AgencyConfiguration | undefined>(state => state?.agency?.configuration);

  const configuration_default: AgencyConfiguration = {
    primary_color: '',
    secondary_color: '',
    main_brand_image_url: '',
    icon_image_url: '',

    // Login
    login_image_url: '',
    login_background_image_url: '',
    login_background_color: '',
    login_card_background_color: '',
    login_card_text_color: '',

    // Logged
    background_image_url: '',
    background_color: '',
    text_color: '',
    text_secondary_color: '',

    // Menu
    menu_background_color: '',
    menu_selected_item_background_color: '',
    menu_hover_item_background_color: '',
    menu_text_color: '',
    menu_text_selected_color: '',

    // Card
    card_background_color: '',
    card_text_primary_color: '',
    card_text_secondary_color: '',
    card_shadow_color: '',
    card_border_color: '',

    // Chat
    allow_chat: false,
  };

  return <ThemeProvider theme={configuration || configuration_default}>{children}</ThemeProvider>;
};

export default Theme;

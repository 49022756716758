import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { IState } from '../store';
import { EnumPermission } from '../types/enum/EnumPermission';

export interface IUsePermission {
  user_permissions: Permissions;
  userHasPermission(perm: EnumPermission): boolean;
  agency_name?: string;
}

type PermissionsKeys = keyof typeof EnumPermission;
type Permissions = {
  [Property in PermissionsKeys]: boolean;
};

export function usePermission(): IUsePermission {
  const redux_permission = useSelector((state: IState) => state.permission);
  const redux_agency = useSelector((state: IState) => state.agency.info?.name);

  const user_permissions = useMemo(() => {
    const final_permission: Permissions = {} as Permissions;
    const permissions_copy = [...(redux_permission?.permissions || [])];

    Object.entries(EnumPermission).forEach(([key, val]) => {
      if (Number.isNaN(Number(key))) {
        final_permission[key as PermissionsKeys] = permissions_copy.some(perm => perm === val);
      }
    });
    return final_permission;
  }, [redux_permission.permissions]);

  function userHasPermission(perm: EnumPermission): boolean {
    return !!redux_permission.permissions?.find(item => item === perm);
  }

  const agency_name = useMemo(() => {
    return redux_agency;
  }, [redux_agency]);

  return { user_permissions, userHasPermission, agency_name };
}

import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
// import { useLocation } from 'react-router';

import { IState } from '../../store';
import StandardLayout from './Default';
import AuthLayout from './Auth';

type IProps = { children: React.ReactNode | React.ReactElement };

const Layouts: React.FC<IProps> = ({ children }: IProps) => {
  const authenticated = useSelector<IState, boolean | undefined>(state => state.auth.authenticated);
  // const location = useLocation();

  // useEffect(() => {
  //   console.log('children', children);
  // }, [children]);

  const Layout = useMemo(() => {
    if (authenticated) {
      // if (location.pathname.startsWith('/payment/')) {
      //   return AuthLayout;
      // }

      return StandardLayout;
    }

    return AuthLayout;
  }, [
    authenticated,
    // location
  ]);

  return <Layout>{children}</Layout>;
};

export default Layouts;

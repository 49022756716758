import { Reducer } from 'redux';

import api from '../../../services/api';
import { EnumFeatures } from '../../../types/enum/EnumFeatures';
import TypeAccess from '../../../types/enum/TypeAccess';

import { EnumAuthActions, SignInRequest } from './actions';

export interface IAuthState {
  authenticated: boolean;
  global_loading: boolean;
  token?: string;
  type_access?: TypeAccess;
  features?: EnumFeatures[];
}

const initial_state: IAuthState = {
  global_loading: true,
  authenticated: false,
  token: undefined,
  type_access: undefined,
  features: undefined,
};

const auth: Reducer<IAuthState> = (state = initial_state, action) => {
  switch (action.type) {
    case EnumAuthActions.SIGN_IN:
      const { payload } = action as unknown as { payload: SignInRequest };

      api.defaults.headers.authorization = `Bearer ${payload.token}`;

      return {
        ...state,
        authenticated: payload.authenticated,
        token: payload.token,
        type_access: payload.type_access,
      };

    case EnumAuthActions.SIGN_OUT:
      api.defaults.headers.authorization = ``;

      return {
        ...initial_state,
        global_loading: false,
      };

    case EnumAuthActions.SET_GLOBAL_LOADING:
      const { loading } = action.payload as unknown as { loading: boolean };

      return {
        ...state,
        global_loading: loading,
      };

    default:
      return state;
  }
};

export default auth;

import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Typography } from 'antd';

import { useMenu } from '../../../hooks/useMenu';
import { IState } from '../../../store';
import { DashboardTitle } from './styles';
import DashboardService from '../../../services/Dashboard/DashboardService';
import DashboardAgencyCard from './Card';
import { StatusOnboard } from '../../../services/Dashboard/DashboardTypes';
import { Agency } from '../../../types/Agency';

type StatusAccounts = {
  activated: number;
  pre_compliance: number;
  others: number;
  canceled: number;
};

const { Text } = Typography;

const DashboardAgency: React.FC = () => {
  const agency = useSelector<IState, Agency | undefined>(state => state.agency.info);
  const social_name = useSelector<IState, string | undefined>(state => state.user?.social_name);
  const [loadingAccount, setLoadingAccount] = useState<boolean>(true);
  const [loadingOnboard, setLoadingOnboard] = useState<boolean>(true);
  const [accountStatusCounter, setAccountStatusCounter] = useState<StatusAccounts | undefined>();
  const [onboardDashboard, setOnboardDashboard] = useState<StatusOnboard | undefined>();

  const { setPageInfo, setCurrentUrl } = useMenu();

  useEffect(() => {
    setPageInfo({
      title: social_name ? `Olá, ${social_name}!` : 'Olá, seja bem vindo!',
      subtitle: 'Dashboard',
    });
  }, [social_name, setPageInfo]);
  useEffect(() => {
    setCurrentUrl('/dashboard');
  }, [setCurrentUrl]);

  useEffect(() => {
    Promise.all([searchAccountStatus(), searchOnboardStatus()]);
  }, []);

  const onboard_data = useMemo(() => {
    return {
      total:
        (onboardDashboard?.approved || 0) +
        (onboardDashboard?.in_process || 0) +
        (onboardDashboard?.reproved || 0) +
        (onboardDashboard?.under_analysis || 0),
      processed: (onboardDashboard?.reproved || 0) + (onboardDashboard?.approved || 0),
    };
  }, [onboardDashboard]);

  async function searchAccountStatus() {
    setLoadingAccount(true);

    const response = await DashboardService.searchAndCountAccountStatus();

    setLoadingAccount(false);

    if (!response) {
      return;
    }

    setAccountStatusCounter(response.data);
  }
  async function searchOnboardStatus() {
    if (is_girabank) {
      return;
    }

    setLoadingOnboard(true);

    const response = await DashboardService.getOnboardDashboard();

    setLoadingOnboard(false);

    if (!response) {
      return;
    }

    setOnboardDashboard(response);
  }

  const is_girabank = useMemo(() => {
    return agency?.bank_url === 'https://application.girabank.com.br';
  }, [agency]);

  return (
    <>
      <DashboardTitle>Contas</DashboardTitle>

      <Row gutter={16} wrap>
        <Col>
          <DashboardAgencyCard
            loading={loadingAccount}
            title="Ativas"
            content={accountStatusCounter?.activated || 0}
            to="/accounts?id_status_account=5"
          />
        </Col>
        {/* <Col>
          <DashboardAgencyCard
            loading={loadingAccount}
            title="Pré Compliance"
            content={accountStatusCounter?.pre_compliance || 0}
            to="/pre-compliance"
          />
        </Col> */}
        <Col>
          <DashboardAgencyCard
            loading={loadingAccount}
            title="Canceladas"
            content={accountStatusCounter?.canceled || 0}
            to="/accounts?id_status_account=6"
          />
        </Col>
        <Col>
          <DashboardAgencyCard
            loading={loadingAccount}
            title="Outros"
            content={accountStatusCounter?.others || 0}
            to="/accounts?id_status_account=1,2,3,4,7,8,9,10,11,12"
          />
        </Col>
      </Row>
      <Text type="secondary">Total</Text>

      {!is_girabank ? (
        <>
          <DashboardTitle>Onboard</DashboardTitle>

          <Row gutter={16} wrap>
            <Col>
              <DashboardAgencyCard loading={loadingOnboard} title="Total" content={onboard_data.total || 0} to="/onboard" />
            </Col>
            <Col>
              <DashboardAgencyCard loading={loadingOnboard} title="Em processo" content={onboardDashboard?.in_process || 0} to="/onboard" />
            </Col>
            <Col>
              <DashboardAgencyCard loading={loadingOnboard} title="Em análise" content={onboardDashboard?.under_analysis || 0} to="/onboard" />
            </Col>
            <Col>
              <DashboardAgencyCard loading={loadingOnboard} title="Processados" content={onboard_data.processed || 0} to="/onboard" />
            </Col>
          </Row>
          <Text type="secondary">Nos últimos 30 dias</Text>
        </>
      ) : null}
    </>
  );
};

export default DashboardAgency;

import { Reducer } from 'redux';
import { GetDashboardInfo } from 'src/services/Dashboard/DashboardTypes';
import { EnumAuthActions } from '../auth/actions';
import { EnumHomeActions } from './actions';

export type IHomeState = GetDashboardInfo;

const initial_state: IHomeState = {} as IHomeState;

const home: Reducer<IHomeState> = (state = initial_state, action) => {
  switch (action.type) {
    case EnumHomeActions.UPDATE_HOME:
      const { payload } = action as unknown as { payload: IHomeState };

      return payload;

    case EnumAuthActions.SIGN_OUT:
      return initial_state;

    default:
      return state;
  }
};

export default home;

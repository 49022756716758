import { Link } from 'react-router-dom';
import styled from 'styled-components';

import DeviceSize from '../../../styles/DeviceSize';

export const Container = styled.div`
  font-family: 'Nunito', sans-serif;
  width: 100%;
  display: flex;
  /* align-items: end; */
  justify-content: space-between;

  padding: 55px 0 35px;

  @media screen and (max-width: ${DeviceSize.LAPTOP}) {
    flex-direction: column-reverse;
  }
`;

export const Header = styled.div`
  h2 {
    font-size: 18px;
    color: ${props => props.theme.text_secondary_color};
    margin-bottom: -10px;
  }

  h1 {
    font-size: 36px;
    color: ${props => props.theme.text_color};
    font-weight: bold;
    margin: none;
  }

  @media screen and (max-width: ${DeviceSize.LAPTOP}) {
    margin-top: 50px;
  }
`;

export const Cards = styled.div`
  display: flex;

  @media screen and (max-width: ${DeviceSize.LAPTOP}) {
    justify-content: space-between;
  }

  @media screen and (max-width: ${DeviceSize.TABLET}) {
    flex-direction: column-reverse;
  }
`;

export const CardBalance = styled(Link)`
  background-color: ${props => props.theme.card_background_color};
  border: 1px solid ${props => props.theme.card_border_color};
  font-size: 14px;

  max-width: 377px;
  height: 74px;
  border-radius: 20px;
  padding: 20px;
  margin-right: 5px;

  display: flex;
  align-items: baseline;
  justify-content: center;
  flex-direction: column;

  span {
    color: ${props => props.theme.text_secondary_color};
    margin-bottom: -8px;
  }
  strong {
    font-size: 20px;
    color: ${props => props.theme.text_color};
  }

  @media screen and (max-width: ${DeviceSize.TABLET}) {
    margin-top: 10px;
    margin-right: 0;
  }
`;

export const CardUser = styled.div`
  background-color: ${props => props.theme.card_background_color};
  border: 1px solid #1a1a1a10;
  font-size: 14px;

  max-width: 377px;
  height: 74px;
  border-radius: 20px;
  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Account = styled.div`
  width: 115px;
`;
export const AccountItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    color: ${props => props.theme.text_secondary_color};
  }
`;

export const User = styled.div`
  display: flex;
  margin-left: 16px;
`;
export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-right: 9px;

  strong,
  span {
    white-space: nowrap;
    overflow: hidden;
    max-width: 150px;
    text-overflow: ellipsis;
  }

  span {
    color: ${props => props.theme.text_secondary_color};
  }
`;
export const UserPic = styled.div`
  background-color: #c4c4c4;
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

import React, { useMemo } from 'react';
import {
  FaHome,
  FaExchangeAlt,
  FaBarcode,
  FaCog,
  // FaCreditCard,
  // FaHandHoldingUsd,
  // FaMobileAlt,
  FaUserTie,
  FaListAlt,
} from 'react-icons/fa';

import { useSelector } from 'react-redux';
import { Container } from './styles';
import MenuItem from './MenuItem';
// import PixIcon from '../../Icons/PixIcon';
import { usePermission } from '../../../hooks/usePermission';
import { EnumFeatures } from '../../../types/enum/EnumFeatures';
import { IState } from '../../../store';

type IAgencyMenu = {
  handleMenuItemClick(url: string): void;
};

const AccountMenu: React.FC<IAgencyMenu> = ({ handleMenuItemClick }: IAgencyMenu) => {
  const { user_permissions } = usePermission();
  const features = useSelector<IState, EnumFeatures[] | undefined>(state => state.agency.features);

  const menu_item = useMemo(() => {
    return (
      <>
        <MenuItem name="Home" url="/dashboard" icon={<FaHome />} onClick={handleMenuItemClick} />

        {user_permissions.VIEW_TRANSACTION && <MenuItem name="Extrato" url="/transactions" icon={<FaListAlt />} onClick={handleMenuItemClick} />}

        {/* {(user_permissions.CREATE_PIX_TRANSFER || user_permissions.MANAGE_PIX_KEYS) && features?.includes(EnumFeatures.PIX) && (
          <MenuItem name="Área PIX" url="/pix" icon={<PixIcon />} onClick={handleMenuItemClick} />
        )} */}

        {/* {user_permissions.MANAGE_CARD && features?.includes(EnumFeatures.CARDS) && (
          <MenuItem name="Meus Cartões" url="/card" icon={<FaCreditCard />} onClick={handleMenuItemClick} />
        )} */}

        {user_permissions.CREATE_TED_P2P_TRANSFER && features?.includes(EnumFeatures.TRANSFERS) && (
          <MenuItem name="Transferir" url="/transfer" icon={<FaExchangeAlt />} onClick={handleMenuItemClick} />
        )}

        {user_permissions.CREATE_BANKSLIP_PAYMENT && features?.includes(EnumFeatures.PAYMENTS) && (
          <MenuItem name="Pagar" url="/bankslip/payment" icon={<FaBarcode />} onClick={handleMenuItemClick} />
        )}

        {(user_permissions.VIEW_BANKSLIP || user_permissions.CANCEL_BANKSLIP || user_permissions.CREATE_BANKSLIP) &&
          features?.includes(EnumFeatures.BANKSLIP) && (
            <MenuItem name="Meus Boletos" url="/bankslip" icon={<FaBarcode />} onClick={handleMenuItemClick} />
          )}

        {/* {user_permissions.USE_DIGITAL_SERVICES && features?.includes(EnumFeatures.USE_DIGITAL_SERVICES) && (
          <MenuItem name="Recarga de Celular" url="/phone-recharge" icon={<FaMobileAlt />} onClick={handleMenuItemClick} />
        )} */}

        {/* {user_permissions.MANAGE_PAYMENT_LINK && features?.includes(EnumFeatures.PAYMENT_LINK) && (
          <MenuItem name="Link de Pagamento" url="/payment_link" icon={<FaHandHoldingUsd />} onClick={handleMenuItemClick} />
        )} */}

        {user_permissions.CREATE_GUEST_ACCOUNT_USER && features?.includes(EnumFeatures.MULTIPLE_USER_ACCOUNT) && (
          <MenuItem name="Usuários" url="/user_guest_account" icon={<FaUserTie />} onClick={handleMenuItemClick} />
        )}

        <MenuItem name="Conta" url="/config" icon={<FaCog />} onClick={handleMenuItemClick} />
      </>
    );
  }, [user_permissions, features]);

  return (
    <>
      <Container>{menu_item}</Container>
    </>
  );
};

export default AccountMenu;

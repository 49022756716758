import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 36px;
  margin-bottom: 22px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 226px;
  }
`;

import { Agency, AgencyConfiguration } from '../../../types/Agency';
import { EnumFeatures } from '../../../types/enum/EnumFeatures';
import { ActionReturn } from '../rootReducer';

export enum EnumAgencyActions {
  SET_CONFIGURATION = 'SET_CONFIGURATION',
}

export interface ISetConfiguration {
  configuration: AgencyConfiguration;
  info: Agency;
  last_time_updated: number;
  features: EnumFeatures[];
}

export function setConfiguration(data: ISetConfiguration): ActionReturn {
  return {
    type: EnumAgencyActions.SET_CONFIGURATION,
    payload: data,
  };
}

export enum EnumPermission {
  // Conta
  VIEW_TRANSACTION = 1,
  CREATE_PIX_TRANSFER = 2,
  CREATE_TED_P2P_TRANSFER = 3,

  VIEW_BANKSLIP = 4,
  CREATE_BANKSLIP_PAYMENT = 5,
  CREATE_BANKSLIP = 6,
  CANCEL_BANKSLIP = 8,

  MANAGE_PIX_KEYS = 9,
  MANAGE_PAYMENT_LINK = 10,
  MANAGE_CARD = 11,

  USE_DIGITAL_SERVICES = 12,

  REQUEST_TRANSACTION = 13,
  REQUEST_ACCOUNT_MODEL = 14,

  // Agency
  UPDATE_INTEGRATION_KEYS = 15,
  MANAGE_STYLE = 16,
  MANAGE_PRE_COMPLIANCE = 17,
  MANAGE_ACCOUNTS = 18,
  MANAGE_AGENCY_USERS = 19,
  MANAGE_MODEL_ACCOUNT = 20,
  MANAGE_PROFILES = 21,
  VIEW_REPORT = 22,
  VIEW_ALL_TRANSACTIONS = 23,
  CREATE_GUEST_ACCOUNT_USER = 24,
  VIEW_GIRACLUB = 25,
  CREATE_RAFFLE_WINNER = 26,
  VIEW_CREDIT_ANALYSIS_LIST = 27,
  VIEW_CARDS_LIST = 28,

  UPDATE_PHONE_NUMBER = 29,
}

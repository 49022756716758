import React, { useMemo } from 'react';
// import { AiFillHeart } from 'react-icons/ai';
import {
  FaHome,
  FaUserLock,
  FaUserFriends,
  FaLayerGroup,
  FaCog,
  FaUserCog,
  FaClipboardList,
  FaUserEdit,
  FaChartPie,
  FaBuromobelexperte,
  FaSearchDollar,
} from 'react-icons/fa';
import { GiSunflower } from 'react-icons/gi';
import { MdCreditCard, MdInsertDriveFile } from 'react-icons/md';
import { EnumFeatures } from 'src/types/enum/EnumFeatures';

import { EnumPermission } from '../../../types/enum/EnumPermission';
import MenuItem from './MenuItem';
import { Container } from './styles';
import SubmenuItem from './SubmenuItem';

type IAgencyMenu = {
  handleMenuItemClick(url: string): void;
};

const AgencyMenu: React.FC<IAgencyMenu> = ({ handleMenuItemClick }: IAgencyMenu) => {
  const menu_item = useMemo(() => {
    return (
      <>
        <MenuItem name="Home" url="/dashboard" icon={<FaHome />} onClick={handleMenuItemClick} />

        <SubmenuItem
          name="Onboard"
          icon={<FaChartPie />}
          defaultSub={0}
          onClick={handleMenuItemClick}
          sub={[
            { name: 'Onboard', icon: <FaChartPie />, url: '/onboard', onClick: handleMenuItemClick, permission: EnumPermission.MANAGE_ACCOUNTS },
            {
              name: 'Pré Compliance',
              icon: <FaUserLock />,
              url: '/pre-compliance',
              onClick: handleMenuItemClick,
              permission: EnumPermission.MANAGE_PRE_COMPLIANCE,
            },
          ]}
        />

        <SubmenuItem
          name="Conta"
          icon={<FaUserFriends />}
          defaultSub={0}
          onClick={handleMenuItemClick}
          sub={[
            {
              name: 'Contas',
              icon: <FaUserFriends />,
              url: '/accounts',
              onClick: handleMenuItemClick,
              permission: EnumPermission.MANAGE_ACCOUNTS,
            },
            {
              name: 'Extrato Geral',
              icon: <MdInsertDriveFile />,
              url: '/statements',
              onClick: handleMenuItemClick,
              permission: EnumPermission.VIEW_ALL_TRANSACTIONS,
            },
          ]}
        />

        <SubmenuItem
          name="Cartão"
          icon={<MdCreditCard />}
          defaultSub={0}
          onClick={handleMenuItemClick}
          sub={[
            {
              name: 'Cartões',
              icon: <MdCreditCard />,
              url: '/cards',
              onClick: handleMenuItemClick,
              permission: EnumPermission.VIEW_CARDS_LIST,
              feature: EnumFeatures.CARDS,
            },
            {
              name: 'Analise de crédito',
              icon: <FaSearchDollar />,
              url: '/credit-analysis',
              onClick: handleMenuItemClick,
              permission: EnumPermission.VIEW_CREDIT_ANALYSIS_LIST,
              feature: EnumFeatures.CREDIT_CARD_ANALYSIS,
            },
          ]}
        />

        <SubmenuItem
          name="Girabank"
          icon={<GiSunflower />}
          defaultSub={0}
          sub={[
            {
              name: 'Giraclub',
              icon: <GiSunflower />,
              url: '/giraclub',
              onClick: handleMenuItemClick,
              permission: EnumPermission.VIEW_GIRACLUB,
              feature: EnumFeatures.GIRACLUB,
            },
            {
              name: 'Sorteios',
              icon: <FaBuromobelexperte />,
              url: '/giraclub/raffle',
              onClick: handleMenuItemClick,
              permission: EnumPermission.VIEW_GIRACLUB,
              feature: EnumFeatures.GIRACLUB,
            },
          ]}
        />

        <SubmenuItem
          name="Admin"
          icon={<FaUserCog />}
          defaultSub={0}
          sub={[
            { name: 'Relatórios', icon: <FaClipboardList />, url: '/reports', onClick: handleMenuItemClick, permission: EnumPermission.VIEW_REPORT },
            {
              name: 'Usuários de Acesso',
              icon: <FaUserCog />,
              url: '/user_agency',
              onClick: handleMenuItemClick,
              permission: EnumPermission.MANAGE_AGENCY_USERS,
            },
            {
              name: 'Modelos de Contas',
              icon: <FaLayerGroup />,
              url: '/account-model',
              onClick: handleMenuItemClick,
              permission: EnumPermission.MANAGE_MODEL_ACCOUNT,
            },
            { name: 'Perfis', icon: <FaUserEdit />, url: '/profile', onClick: handleMenuItemClick, permission: EnumPermission.MANAGE_PROFILES },
            { name: 'Ajustes', icon: <FaCog />, url: '/config', onClick: handleMenuItemClick },
          ]}
        />
      </>
    );
  }, [handleMenuItemClick]);

  return (
    <>
      <Container>{menu_item}</Container>
    </>
  );
};

export default AgencyMenu;

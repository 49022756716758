import { useDispatch, useSelector } from 'react-redux';

import { IState } from '../store';
import AccountFee from '../types/AccountFee';
import EnumTransactionType from '../types/enum/TransactionType';
import AccountService from '../services/Account/AccountService';
import { setAccountFees } from '../store/modules/account/actions';

type IApplyFee = {
  total_amount: number;
  fee: number;
};

export interface IUseAccountFees {
  account_fees: AccountFee[];
  getFee: (id_transaction_type: EnumTransactionType) => AccountFee | undefined;
  updateAccountFee: () => void;
  applyFee: (amount: number, id_transaction_type: EnumTransactionType) => IApplyFee;
}

export function useAccountFees(): IUseAccountFees {
  const dispatch = useDispatch();
  const account_fees = useSelector<IState, AccountFee[]>(state => state.account?.fees || []);

  function getFee(id_transaction_type: EnumTransactionType): AccountFee | undefined {
    return account_fees.find(fee => fee.id_transaction_type === id_transaction_type);
  }

  function applyFee(amount: number, id_transaction_type: EnumTransactionType): IApplyFee {
    const transaction_fee = getFee(id_transaction_type);

    if (!transaction_fee) {
      return {
        fee: 0,
        total_amount: amount,
      };
    }

    const { fee, fee_type } = transaction_fee;

    let total_amount = amount;

    if (fee_type === 'fixed') {
      total_amount += fee;
    }

    if (fee_type === 'relative') {
      total_amount += amount * (fee / 100);
    }

    return { total_amount, fee };
  }

  async function updateAccountFee() {
    if (account_fees?.length) {
      return null;
    }

    const fees = await AccountService.getAccountFee();

    if (fees) {
      dispatch(setAccountFees(fees));
    }

    return null;
  }

  return { account_fees, getFee, updateAccountFee, applyFee };
}

import { ActionReturn } from '../rootReducer';
import { IHomeState } from './reducer';

export enum EnumHomeActions {
  UPDATE_HOME = 'UPDATE_HOME',
}

export function updateHome(data: IHomeState): ActionReturn {
  return {
    type: EnumHomeActions.UPDATE_HOME,
    payload: data,
  };
}

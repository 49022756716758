import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import ReactSEOMetaTags, { WebsiteProps } from 'react-seo-meta-tags';
import { IState } from '../../store';

const SEOConfigurations: React.FC = () => {
  const agency_configuration = useSelector<IState, any | undefined>(state => state.agency);

  const site_metadata = useMemo(() => {
    if (!agency_configuration) {
      return undefined;
    }

    const params: SiteMetadata = {
      website: {
        url: agency_configuration?.info?.bank_url,
        title: agency_configuration?.info?.name,
        language: 'pt-BR',
        image: agency_configuration?.main_brand_image_url,
      },
      keywords: `${agency_configuration?.info?.name},bank,banco,banco digital`,
    };

    return params;
  }, [agency_configuration]);

  return (
    <ReactSEOMetaTags
      render={(element: React.ReactNode) => (
        <>
          <Helmet>{element}</Helmet>
          <Helmet>
            <meta name="keywords" content={site_metadata?.keywords} />
          </Helmet>
        </>
      )}
      website={site_metadata?.website}
    />
  );
};

export default SEOConfigurations;

type SiteMetadata = {
  website: WebsiteProps;
  keywords: string;
};

import { notification, Modal } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';

import AuthService from '../../services/Auth/AuthService';
import { signOut } from '../../store/modules/auth/actions';
import { IState } from '../../store';
import environment from '../../config/environment';

const UserInactivity: React.FC = () => {
  const dispatch = useDispatch();
  const is_authenticated = useSelector<IState, boolean>(state => state.auth.authenticated);

  const handleOnIdle = async () => {
    if (environment.env === 'development') {
      return;
    }

    if (!is_authenticated) {
      return;
    }

    const resp = await AuthService.logout();

    if (resp) {
      dispatch(signOut());

      Modal.destroyAll();

      notification.info({
        message: 'Deslogado por inatividade',
        description: 'Você foi deslogado por ficar inativo',
        duration: null,
      });
    }
  };

  useIdleTimer({
    timeout: 1000 * 60 * 60 * 6, // 6 horas
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return null;
};

export default UserInactivity;

import { StatusAccount, AccountFull } from '../../types/Account';
import AccountFee from '../../types/AccountFee';
import { Pagination } from '../../utils/Pagination';
import { fetchApi, FetchApiI, FetchApiResponseI } from '../api';
import { Onboard } from '../Onboard/OnboardTypes';
import { TransactionFull } from '../Transaction/types';
import {
  AccountStatusFull,
  CreateAccountPhysicalPerson,
  ApproveOrRefuseAccount,
  CreateAccountPhysicalPersonResponse,
  CreateAccountLegalPerson,
  CreateAccountLegalPersonResponse,
  AccountListFilter,
  AccountHolderInfo,
  IUpdatePassword,
  UserGuestFullResponse,
  CreateGuestUserAccount,
  CreateGuestUserAccountResponse,
  DeleteUserGuestProps,
  GetAccountIntegratorEventsResponse,
  GetAccountIntegratorEventsRequest,
  UpdateAccountModelRequest,
  UpdateAccountModelResponse,
  Page,
  ListAccessHistoryRequest,
  GetUserCardsByIDResponse,
  AccountCancellationResponse,
  CreatePJAccountByAdminRequest,
  CreatePJAccountByAdminResponse,
  IBlockAccountCard,
  AccountIsActiveResponse,
  IGetCardInfoResponse,
  CreateAccessKeyData,
  updateAccountPhoneNumberResponse,
  RemoveAccountPixKeyByAdminResponse,
  GetAccountPixKeysByAdminResponse,
  GetAccountOffboardResponse,
  ListAccountDevicesResponse,
  RemoveAccessHistoryRequest,
} from './AccountTypes';

class AccountService {
  private status_account: StatusAccount[] | undefined;

  async registerPhysicalPerson(params: CreateAccountPhysicalPerson): Promise<CreateAccountPhysicalPersonResponse | undefined> {
    const form = new FormData();

    form.append('document_type', params.document_type);
    form.append('address', JSON.stringify(params.address));
    form.append('information', JSON.stringify(params.information));
    form.append('password', JSON.stringify(params.password));

    params.files.forEach(file => {
      form.append(file.id_document_type.toString(), file.data);
    });

    const data: FetchApiI = {
      url: '/auth/account/pf',
      method: 'post',
      data: form,
    };

    return fetchApi(data);
  }

  async registerLegalPerson(params: CreateAccountLegalPerson): Promise<CreateAccountLegalPersonResponse | undefined> {
    const form = new FormData();

    form.append('address', JSON.stringify(params.address));
    form.append('information', JSON.stringify(params.information));
    form.append('password', JSON.stringify(params.password));
    form.append('legal_representative', JSON.stringify(params.legal_representative));

    params.files.forEach(file => {
      form.append(file.id_document_type.toString(), file.data);
    });

    const data: FetchApiI = {
      url: '/auth/account/pj',
      method: 'post',
      data: form,
    };

    return fetchApi(data);
  }

  async approveOrRefuseAccount(dataRequest: ApproveOrRefuseAccount): Promise<FetchApiResponseI<string> | undefined> {
    const data: FetchApiI = {
      url: '/onboard/admin/pre-compliance',
      method: 'post',
      data: dataRequest,
    };

    return fetchApi(data);
  }

  async preComplianceList(): Promise<Pagination<Onboard> | undefined> {
    const data: FetchApiI = {
      url: '/onboard/admin/pre-compliance',
      method: 'get',
    };

    return fetchApi(data);
  }

  async accountList(filter: AccountListFilter): Promise<Pagination<any> | undefined> {
    const data: FetchApiI = {
      url: '/admin/account',
      query_params: filter,
      method: 'get',
    };

    return fetchApi(data);
  }

  async accountStatusList(cache = true): Promise<StatusAccount[] | undefined> {
    if (cache && this.status_account) {
      return this.status_account;
    }

    const data: FetchApiI = {
      url: '/admin/account/status',
      method: 'get',
    };

    const response = await fetchApi(data);

    this.status_account = response;

    return response;
  }

  async getInfoById(id_account: string): Promise<AccountFull | undefined> {
    const data: FetchApiI = {
      url: `/admin/account/${id_account}`,
      method: 'get',
    };

    return fetchApi(data);
  }

  async getAccountTransactionsForDownload(id_account: string, start_day: string, last_day: string): Promise<TransactionFull[] | undefined> {
    return fetchApi({
      url: `/admin/account/${id_account}/transactions-for-download`,
      query_params: { start_day, last_day },
      method: 'get',
    });
  }

  async getClientTransactionsForDownload(id_account: string, start_day: string, last_day: string): Promise<TransactionFull[] | undefined> {
    return fetchApi({
      url: `/account/${id_account}/transactions-for-download`,
      query_params: { start_day, last_day },
      method: 'get',
    });
  }

  async getAccountTransactions(id_account: string, page: number, pageSize: number, filter: any): Promise<Pagination<TransactionFull> | undefined> {
    return fetchApi({
      url: `/admin/account/${id_account}/transactions`,
      query_params: { page, pageSize, ...filter },
      method: 'get',
    });
  }

  async getAccountStatusHistorical(id_account: string): Promise<AccountStatusFull[] | undefined> {
    return fetchApi({
      url: `/admin/account/${id_account}/status`,
      method: 'get',
    });
  }

  async getAccountDocuments(id_account: string): Promise<string[] | undefined> {
    return fetchApi({
      url: `/admin/account/${id_account}/documents`,
      method: 'get',
    });
  }

  async getAccountHolderInfo(id_account: string): Promise<AccountHolderInfo | undefined> {
    return fetchApi({
      url: `/admin/account/${id_account}/holder`,
      method: 'get',
    });
  }

  async getAccountApiKeys(id_account: string): Promise<any | undefined> {
    return fetchApi({
      url: `/auth/token/account/${id_account}`,
      method: 'get',
    });
  }

  async getAccountIntegratorEvents(data_request: GetAccountIntegratorEventsRequest): Promise<GetAccountIntegratorEventsResponse | undefined> {
    const { id_account, page, page_size, begin_date, end_date } = data_request;

    const data: FetchApiI = {
      url: `/admin/account/${id_account}/events`,
      query_params: { page, page_size, begin_date, end_date },
      method: 'get',
    };

    return fetchApi(data);
  }

  async createAccessKey(id_account: string, data: CreateAccessKeyData): Promise<any> {
    return fetchApi({
      url: `/auth/token/account/${id_account}`,
      method: 'post',
      data,
      messages: {
        success: 'Chave de Acesso criada com sucesso',
        loading: 'Criando chave de acesso da API, aguarde...',
        error: 'Erro ao criar a chave!',
      },
    });
  }

  async deleteApiClientKey(id_api_client_key: string): Promise<boolean | undefined> {
    return fetchApi({
      url: `/auth/token/account/key/${id_api_client_key}`,
      method: 'delete',
      messages: {
        loading: 'Removendo chave de acesso da API, aguarde...',
        error: 'Erro ao remover chave, tente novamente!',
        success: 'Chave removida com sucesso!',
      },
    });
  }

  async verifyStatus(): Promise<boolean | undefined> {
    const data: FetchApiI = {
      url: '/admin/account/verify-account-status',
      method: 'get',
    };

    return fetchApi<boolean | undefined>(data);
  }

  async updatePassword(data: IUpdatePassword): Promise<boolean | undefined> {
    const request_data: FetchApiI = {
      url: '/admin/account/password',
      method: 'put',
      data,
      messages: {
        error: 'Erro ao alterar senha, tente novamente!',
        success: 'Senha alterada com sucesso!',
      },
    };

    return fetchApi<boolean | undefined>(request_data);
  }

  async getAccountFee(): Promise<AccountFee[] | undefined> {
    return fetchApi({
      url: '/account/fees',
      method: 'get',
    });
  }

  async updateStatus(id_account: string, id_status_account: string, message: string): Promise<boolean | undefined> {
    const request_data: FetchApiI = {
      url: `/admin/account/${id_account}/status/${id_status_account}`,
      method: 'put',
      data: { message },
      messages: {
        error: 'Erro ao alterar o status, tente novamente!',
        success: 'Status alterada com sucesso!',
      },
    };
    return fetchApi<boolean | undefined>(request_data);
  }

  async suspendAccountByAdmin(id_account: string, message: string): Promise<boolean | undefined> {
    const request_data: FetchApiI = {
      url: `/admin/account/${id_account}/suspension`,
      method: 'put',
      data: { message },
      messages: {
        error: 'Erro ao suspender conta, tente novamente!',
        success: 'Conta suspensa com sucesso!',
      },
    };
    return fetchApi<boolean | undefined>(request_data);
  }

  async activateAccountByAdmin(id_account: string, message: string): Promise<boolean | undefined> {
    const request_data: FetchApiI = {
      url: `/admin/account/${id_account}/activate`,
      method: 'put',
      data: { message },
      messages: {
        error: 'Erro ao ativar conta, tente novamente!',
        success: 'Conta ativada com sucesso!',
      },
    };
    return fetchApi<boolean | undefined>(request_data);
  }

  async cancelAccountByAdmin(id_account: string, message: string): Promise<boolean | undefined> {
    const request_data: FetchApiI = {
      url: `/admin/account/${id_account}/cancel`,
      method: 'delete',
      data: { message },
      messages: {
        error: 'Erro ao cancelar conta, tente novamente!',
        success: 'Conta cancelada com sucesso!',
      },
    };
    return fetchApi<boolean | undefined>(request_data);
  }

  async insertAccountNumber(id_account: string, account_number: string): Promise<boolean | undefined> {
    const request_data: FetchApiI = {
      url: `/admin/account/${id_account}`,
      method: 'put',
      data: { account_number },
      messages: {
        error: 'Erro, verifique o número inserido e tente novamente!',
        success: 'Número da conta inserido com sucesso!',
      },
    };
    return fetchApi<boolean | undefined>(request_data);
  }

  async getUsersGuestAccount(): Promise<UserGuestFullResponse[] | undefined> {
    const data: FetchApiI = {
      url: '/user/account/guest',
      method: 'get',
    };
    return fetchApi(data);
  }

  async createUserGuestAccount(props: CreateGuestUserAccount): Promise<CreateGuestUserAccountResponse | undefined> {
    const data: FetchApiI = {
      url: '/user/account/guest',
      method: 'post',
      data: props,
    };
    return fetchApi(data);
  }

  async removeUserGuestAccount({ id_user_account }: DeleteUserGuestProps): Promise<{ success: boolean } | undefined> {
    const data: FetchApiI = {
      url: `/user/guest/${id_user_account}`,
      method: 'delete',
      messages: {
        error: 'Erro ao remover usuário.',
        success: 'Usuário removido com sucesso.',
        loading: 'Removendo usuário, aguarde...',
      },
    };
    return fetchApi(data);
  }

  async updateAccountModel(props: UpdateAccountModelRequest): Promise<UpdateAccountModelResponse | undefined> {
    const data: FetchApiI = {
      url: `/admin/account/${props.id_account}/model-account/${props.id_model_account}`,
      method: 'put',
    };
    return fetchApi(data);
  }

  async listAccountModel(id_account: string, pag: Page): Promise<any | undefined> {
    const data: FetchApiI = {
      url: `/admin/account/${id_account}/model-account`,
      query_params: pag,
      method: 'get',
    };
    return fetchApi(data);
  }

  async listAccessHistory(props: ListAccessHistoryRequest): Promise<any | undefined> {
    const { id_account, page, page_size } = props;

    const data: FetchApiI = {
      url: `/auth/account/${id_account}/history`,
      query_params: { page, page_size },
      method: 'get',
    };
    return fetchApi(data);
  }

  async listAccountDevices(props: ListAccessHistoryRequest): Promise<ListAccountDevicesResponse | undefined> {
    const { id_account, page, page_size } = props;

    const data: FetchApiI = {
      url: `/auth/account/${id_account}/device`,
      query_params: { page, page_size },
      method: 'get',
    };
    return fetchApi(data);
  }

  async removeAccountDevices(props: RemoveAccessHistoryRequest): Promise<{ success: boolean } | undefined> {
    const { id_account, id_account_device } = props;

    const data: FetchApiI = {
      url: `/auth/account/${id_account}/device/${id_account_device}`,
      method: 'delete',
    };

    return fetchApi(data);
  }

  async removeAccess(id_auth_token: string): Promise<any | undefined> {
    const data: FetchApiI = {
      url: `/auth/account/access/${id_auth_token}`,
      method: 'delete',
    };
    return fetchApi(data);
  }

  async getUserCardsByID(id_account: string): Promise<GetUserCardsByIDResponse[] | undefined> {
    const response = fetchApi({
      url: `/admin/account/${id_account}/cards`,
      method: 'get',
    });

    return response;
  }

  async getCardInfo(id_account: string, id_card: string): Promise<IGetCardInfoResponse> {
    const response = fetchApi({
      url: `/admin/account/${id_account}/card/${id_card}/banking`,
      method: 'get',
    });

    return response;
  }

  async blockAccountCard({ id_account, id_card, message }: IBlockAccountCard): Promise<GetUserCardsByIDResponse[] | undefined> {
    const response = fetchApi({
      url: `/admin/account/${id_account}/cards/${id_card}`,
      data: {
        message,
      },
      method: 'delete',
      messages: {
        loading: 'Cancelando cartão, aguarde...',
        error: 'Erro ao cancelar cartão, tente novamente!',
        success: 'Cartão cancelado com sucesso!',
      },
    });

    return response;
  }

  async accountIsActive(id_account: string): Promise<AccountIsActiveResponse | undefined> {
    const data: FetchApiI = {
      url: `/admin/account/${id_account}/is-activated`,
      method: 'get',
    };

    return fetchApi(data);
  }

  async accountCancellation(movimentation_password: string): Promise<AccountCancellationResponse | undefined> {
    const data: FetchApiI = {
      url: '/account',
      method: 'delete',
      data: { movimentation_password },
      messages: {
        success: 'Pedido de cancelamento de conta efetuado com sucesso!',
      },
    };

    return fetchApi(data);
  }

  async sendEmailNotification(id_account: string): Promise<{ success: boolean } | undefined> {
    const data: FetchApiI = {
      url: `/admin/account/${id_account}/notification/open-account`,
      method: 'post',
      messages: {
        success: 'Email para abertura de conta enviado com sucesso!',
        error: 'Erro no envio do email',
      },
    };
    return fetchApi(data);
  }

  async sendSMSlNotification(id_account: string): Promise<{ success: boolean } | undefined> {
    const data: FetchApiI = {
      url: `/admin/account/${id_account}/notification/bank-account`,
      method: 'post',
      messages: {
        success: 'SMS com os dados da conta enviado com sucesso!',
        error: 'Erro no envio do SMS',
      },
    };
    return fetchApi(data);
  }

  async createPJAccountByAdmin(data: CreatePJAccountByAdminRequest): Promise<CreatePJAccountByAdminResponse | undefined> {
    const request_data: FetchApiI = {
      url: `/admin/account/create-manual-account/pj`,
      method: 'post',
      data: { data },
      messages: {
        success: 'Conta criada com sucesso!',
        error: 'Erro ao criar a conta.',
      },
    };
    return fetchApi(request_data);
  }

  async generateNewAccessPassword(id_account: string): Promise<CreatePJAccountByAdminResponse | undefined> {
    const request_data: FetchApiI = {
      url: `/admin/account/${id_account}/password/generation/access`,
      method: 'put',
      messages: {
        success: 'Senha de acesso gerar com sucesso!',
        error: 'Erro ao gerar senha de acesso.',
      },
    };
    return fetchApi(request_data);
  }

  async generateNewMovPassword(id_account: string): Promise<CreatePJAccountByAdminResponse | undefined> {
    const request_data: FetchApiI = {
      url: `/admin/account/${id_account}/password/generation/movimentation`,
      method: 'put',
      messages: {
        success: 'Senha de movimentação gerada com sucesso!',
        error: 'Erro ao gerar senha de movimentação.',
      },
    };
    return fetchApi(request_data);
  }

  async updateAccountPhoneNumberByAdmin(id_account: string, new_phone: string): Promise<updateAccountPhoneNumberResponse | undefined> {
    const request_data: FetchApiI = {
      url: `/admin/account/${id_account}/phone`,
      data: { new_phone },
      method: 'put',
      messages: {
        success: 'Número de celular atualizado com sucesso!',
        error: 'Erro ao atualizar o numero de celular.',
      },
    };
    return fetchApi(request_data);
  }

  async getAccountPixKeysByAdmin(id_account: string): Promise<GetAccountPixKeysByAdminResponse | undefined> {
    const request_data: FetchApiI = {
      url: `/admin/account/${id_account}/pix-keys`,
      method: 'get',
    };
    return fetchApi(request_data);
  }

  async removeAccountPixKeyByAdmin(id_account: string, pix_key: string): Promise<RemoveAccountPixKeyByAdminResponse | undefined> {
    const request_data: FetchApiI = {
      url: `/admin/account/${id_account}/pix-key/${pix_key}`,
      method: 'delete',
      messages: {
        success: 'Chave PIX removida com sucesso !',
        error: 'Erro ao remover a chave PIX.',
      },
    };
    return fetchApi(request_data);
  }

  async getAccountOffboard(): Promise<GetAccountOffboardResponse | undefined> {
    const request_data: FetchApiI = {
      url: `/app/offboard`,
      method: 'get',
    };
    return fetchApi(request_data);
  }
}

export default new AccountService();

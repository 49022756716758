import { Reducer } from 'redux';

import { Balance } from '../../../types/Balance';
import { EnumAuthActions } from '../auth/actions';
import { EnumBalanceActions } from './actions';

export type IBalanceState = Balance | null;

const initial_state: IBalanceState = null;

const balance: Reducer<IBalanceState> = (state = initial_state, action) => {
  switch (action.type) {
    case EnumBalanceActions.UPDATE_BALANCE:
      const { payload } = action as unknown as { payload: Balance };

      return payload;

    case EnumAuthActions.SIGN_OUT:
      return initial_state;

    default:
      return state;
  }
};

export default balance;

import React, { useMemo, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { IState } from '../../../../store';
import { Item } from '../styles';

type IProps = {
  url: string;
  name: string;
  onClick?: (url: string) => any;
  icon?: ReactElement<any, any>;
};

const MenuItem: React.FC<IProps> = ({ name, url, icon, onClick }: IProps) => {
  const current_url = useSelector<IState, string>(state => state.menu.current_url);

  const is_selected = useMemo(() => {
    return url === current_url ? 'selected' : '';
  }, [url, current_url]);

  return (
    <Item>
      <Link to={url} className={is_selected} onClick={() => onClick && onClick(url)}>
        {icon}
        <span>{name}</span>
      </Link>
    </Item>
  );
};

export default MenuItem;

import { Reducer } from 'redux';

import { User } from '../../../types/User';
import { EnumAuthActions } from '../auth/actions';

import { EnumUserActions } from './actions';

export type IUserState = User | null;

const initial_state: IUserState = null;

const user: Reducer<IUserState> = (state = initial_state, action) => {
  switch (action.type) {
    case EnumUserActions.SET_USER_DATA:
      return action.payload;

    case EnumAuthActions.SIGN_IN:
      return action.payload.user;

    case EnumAuthActions.SIGN_OUT:
      return initial_state;

    default:
      return state;
  }
};

export default user;

import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConfigProvider } from 'antd';
import locale from 'antd/lib/locale/pt_BR';

import Routes from './routes';
import { store, persistor } from './store';
import Theme from './styles/Theme';
import GlobalStyle from './styles/global';
import UserInactivity from './components/UserInactivity';
import SEOConfigurations from './components/SEO';
import { ConfirmationProvider } from './contexts/ConfirmationContext';

import 'moment/locale/pt-br';
import 'antd/dist/antd.css';

function App(): JSX.Element {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Theme>
          <Router>
            <ConfirmationProvider>
              <ConfigProvider locale={locale}>
                <Routes />

                <SEOConfigurations />
                <GlobalStyle />
                <UserInactivity />
              </ConfigProvider>
            </ConfirmationProvider>
          </Router>
        </Theme>
      </PersistGate>
    </Provider>
  );
}

export default App;

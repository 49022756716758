import { Reducer } from 'redux';

import { Agency, AgencyConfiguration } from '../../../types/Agency';
import { EnumFeatures } from '../../../types/enum/EnumFeatures';
import { EnumAgencyActions, ISetConfiguration } from './actions';

export interface IAgencyState {
  info?: Agency;
  configuration?: AgencyConfiguration;
  last_time_updated?: number;
  features?: EnumFeatures[];
}

const initial_state: IAgencyState = {
  info: undefined,
  configuration: undefined,
  last_time_updated: undefined,
  features: undefined,
};

const agency: Reducer<IAgencyState> = (state = initial_state, action) => {
  switch (action.type) {
    case EnumAgencyActions.SET_CONFIGURATION:
      const { payload } = action as unknown as { payload: ISetConfiguration };

      return {
        configuration: payload.configuration,
        info: payload.info,
        last_time_updated: payload.last_time_updated,
        features: payload.features,
      };

    default:
      return state;
  }
};

export default agency;

import { fetchApi, FetchApiI } from '../api';
import { GetAccountStatusInfo, GetDashboardInfo, StatusOnboard } from './DashboardTypes';

const DashboardService = {
  searchAndCountAccountStatus: async (): Promise<GetAccountStatusInfo | undefined> => {
    const request: FetchApiI = {
      url: '/admin/dashboard',
      method: 'get',
    };

    return fetchApi(request);
  },
  getOnboardDashboard: async (): Promise<StatusOnboard | undefined> => {
    const request: FetchApiI = {
      url: '/onboard/dashboard',
      method: 'get',
    };

    return fetchApi(request);
  },
  getDashboardInfo: async (): Promise<GetDashboardInfo | undefined> => {
    const request: FetchApiI = {
      url: '/app/home',
      method: 'get',
    };

    return fetchApi(request);
  },
};

export default DashboardService;

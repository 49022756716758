import { Reducer } from 'redux';

import { EnumAuthActions } from '../auth/actions';

export type IPermissionState = {
  permissions: number[];
};

const initial_state: IPermissionState = {
  permissions: [],
};

const permission: Reducer<IPermissionState> = (state = initial_state, action) => {
  switch (action.type) {
    case EnumAuthActions.SIGN_IN:
      return {
        permissions: action.payload.permissions,
      };

    case EnumAuthActions.SIGN_OUT:
      return initial_state;

    default:
      return state;
  }
};

export default permission;

import React, { createContext, useMemo, useState, useRef } from 'react';
import { Modal, Table, Form, Alert, Input, InputRef } from 'antd';
import { dinheiroMask } from 'masks-br';
import { useSelector } from 'react-redux';

import { IState } from '../store';
import EnumTransactionType from '../types/enum/TransactionType';
import { useAccountFees } from '../hooks/useAccountFees';

type IConfirmation = IModalEvents & {
  title?: string;
  amount: number;

  id_transaction_type: EnumTransactionType;
};

export interface IConfirmationContext {
  confirmation(data: IConfirmation): void;
  loading: boolean;
}

const ConfirmationContext = createContext<IConfirmationContext>({} as IConfirmationContext);

type IProps = {
  children: React.ReactNode;
};

type IModalContent = {
  title: string;
  unsufficient_balance: boolean;
  table: IModalAmount;
};
type IModalAmount = {
  amount: number;
  fee?: number;
  total: number;
};

type ITableContent = {
  title: string;
  value: number;
};

type IOnSuccess = {
  movement_password: string;
  values: IModalAmount;
};

type IModalEvents = {
  onSuccess: (data: IOnSuccess) => Promise<boolean>;
  onCancel?: () => void;
};

const ConfirmationProvider: React.FC<IProps> = ({ children }: IProps) => {
  const { applyFee } = useAccountFees();
  const amount_balance = useSelector<IState, number>(state => state.balance?.amount || 0);
  const passwordInput = useRef<InputRef>(null);

  const [modalVisibility, setModalVisibility] = useState<boolean>(false);
  const [modalEvents, setModalEvents] = useState<IModalEvents>();
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<IModalContent>({
    title: 'Confirmação',
    table: {
      amount: 0,
      total: 0,
    },
    unsufficient_balance: false,
  });

  const tableContent: ITableContent[] = useMemo(() => {
    const response = [
      {
        title: 'Saldo Atual',
        value: amount_balance,
      },
      {
        title: 'Valor',
        value: modalContent.table.amount,
      },
    ];

    if (modalContent.table.fee) {
      response.push({
        title: 'Taxa',
        value: modalContent.table.fee,
      });
    }

    return response;
  }, [modalContent.table, amount_balance]);

  const [form] = Form.useForm();

  function confirmation(data: IConfirmation): void {
    const { fee, total_amount: total_amount_without_balance } = applyFee(data.amount, data.id_transaction_type);

    const total_amount = amount_balance - total_amount_without_balance;

    setModalContent(state => ({
      title: data.title || state.title,
      unsufficient_balance: total_amount < 0,
      table: {
        total: total_amount < 0 ? 0 : total_amount,
        amount: data.amount,
        fee,
      },
    }));
    setModalVisibility(true);
    setModalEvents({
      onSuccess: data.onSuccess,
      onCancel: data.onCancel,
    });

    passwordInput.current?.focus();
  }

  function handleOnCancel() {
    setModalVisibility(false);
    setModalLoading(false);
    form.resetFields();
  }

  function onCancel() {
    handleOnCancel();

    if (modalEvents?.onCancel) modalEvents.onCancel();
  }

  async function onOkay(data: any) {
    setModalLoading(true);

    if (modalEvents?.onSuccess) {
      const on_success_response = await modalEvents.onSuccess({
        movement_password: data.movement_password,
        values: modalContent.table,
      });

      setModalLoading(false);

      if (!on_success_response) {
        return null;
      }
    }

    handleOnCancel();

    return null;
  }

  return (
    <ConfirmationContext.Provider value={{ confirmation, loading: modalLoading }}>
      {children}

      <Modal
        visible={modalVisibility}
        onCancel={onCancel}
        centered
        title="Confirmação"
        okText="Confirmar Pagamento"
        onOk={() => form.submit()}
        okButtonProps={{ disabled: modalContent.unsufficient_balance, loading: modalLoading }}
      >
        <Table
          showHeader={false}
          size="small"
          dataSource={tableContent}
          pagination={false}
          columns={[
            {
              dataIndex: 'title',
              key: 'title',
            },
            {
              dataIndex: 'value',
              key: 'value',
              align: 'right',
              render: (val: number) => dinheiroMask(val),
            },
          ]}
          summary={() => {
            return (
              <Table.Summary.Row>
                <Table.Summary.Cell index={1}>
                  <strong>Valor Final</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={2}>
                  <strong>{dinheiroMask(modalContent.table.total)}</strong>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            );
          }}
        />

        <br />
        <br />

        {!modalContent.unsufficient_balance ? (
          <Form form={form} layout="vertical" name="paymentConfirmForm" onFinish={onOkay}>
            <Form.Item
              name="movement_password"
              label="Senha de Movimentação"
              rules={[{ required: true, message: 'Por favor, insira a senha para confirmar o pagamento!' }]}
            >
              <Input.Password ref={passwordInput} />
            </Form.Item>
          </Form>
        ) : (
          <Alert message="Você não possui saldo suficiente" type="warning" showIcon />
        )}
      </Modal>
    </ConfirmationContext.Provider>
  );
};

export { ConfirmationProvider, ConfirmationContext };

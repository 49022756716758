import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { encryptTransform } from 'redux-persist-transform-encrypt';

import rootReducer from './modules/rootReducer';
import { IAuthState } from './modules/auth/reducer';
import { IAgencyState } from './modules/agency/reducer';
import { IBalanceState } from './modules/balance/reducer';
import { IUserState } from './modules/user/reducer';
import { IMenuState } from './modules/menu/reducer';
import { IAccountState } from './modules/account/reducer';
import { IPermissionState } from './modules/permission/reducer';
import { IHomeState } from './modules/home/reducer';

export interface IState {
  auth: IAuthState;
  agency: IAgencyState;
  balance: IBalanceState;
  user: IUserState;
  menu: IMenuState;
  account: IAccountState;
  permission: IPermissionState;
  home: IHomeState;
}

const encryptor = encryptTransform({
  secretKey: String(process.env.REACT_APP_CLIENT_SECRET),
  onError: error => {
    console.error('Persist reducer error -', error);
  },
});

const persistConsig: PersistConfig<any> = {
  key: 'PRUDENTTE_BANK',
  storage,
  transforms: [encryptor],
};

const persistedReducer = persistReducer(persistConsig, rootReducer);

export const store = createStore(persistedReducer, composeWithDevTools());
export const persistor = persistStore(store);

import { message } from 'antd';
import axios, { Method, AxiosRequestConfig, AxiosResponse } from 'axios';

import history from './History';
import UrlQuery from '../utils/UrlQuery';
import { signOut } from '../store/modules/auth/actions';
import { IState, store } from '../store';
import environment from '../config/environment';

const api = axios.create({
  baseURL: environment.base_api_url,
});

store.subscribe(() => {
  const { auth } = store.getState() as IState;

  api.defaults.headers.authorization = `Bearer ${auth.token}`;
});

api.interceptors.response.use(
  data => {
    return data;
  },
  async err => {
    return Promise.reject({
      ...err.response.data,
      statusCode: err.response.status,
    });
  },
);

export interface FetchApiResponseI<T = any> extends AxiosResponse {
  data: T;
}
export interface FetchApiI extends AxiosRequestConfig {
  url: string;
  method: Method;
  query_params?: any;
  messages?: {
    loading?: string;
    success?: string;
    error?: string;
  };
  onSuccess?: (data: any) => void;
  onError?: (error: Error) => void;
}

export async function fetchApi<T = any>(data: FetchApiI): Promise<T | undefined> {
  const { onSuccess, onError } = data;

  const info = { ...data };
  info.messages = {
    ...{
      loading: '',
      success: '',
      error: '',
    },
    ...data.messages,
  };

  const key = Math.random().toString(36).substring(7);

  if (info.messages?.loading) {
    message.loading({
      content: info.messages?.loading,
      key,
      duration: 100000,
    });
  }

  let { url } = info;

  if (info.query_params) {
    url += '?';
    url += UrlQuery(info.query_params);
  }

  try {
    const response = await api({
      method: info.method,
      url,
      data: info.data,
      headers: {
        ...(data.headers || {}),
      },
    });

    if (info.messages?.success) {
      message.success({
        content: info.messages?.success,
        key,
      });
    } else {
      message.destroy();
    }

    if (onSuccess) onSuccess(response.data);

    return response.data;
  } catch (err: any) {
    // console.log(err);

    if (info.messages?.error || err.showMessage) {
      // if (err.statusCode !== 401 && (info.messages?.error || err.showMessage)) {
      message.error({
        content: err.showMessage ? err.message : info.messages?.error,
        key,
      });
    }

    if (onError) onError(err);

    if (err.statusCode === 401) {
      const wid = { ...window };
      const redirect_url = `/login?redirect=${wid.location.pathname}`;

      store.dispatch(signOut());

      history.push(redirect_url);
    }

    return undefined;
  }
}

export default api;

import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowLeft } from 'react-icons/md';
import { Link, useHistory } from 'react-router-dom';
import { BsArrowRightShort } from 'react-icons/bs';
import { useSelector } from 'react-redux';

import { EnumPermission } from '../../../../types/enum/EnumPermission';
import { EnumFeatures } from '../../../../types/enum/EnumFeatures';
import { useFeature } from '../../../../hooks/useFeature';
import { IState } from '../../../../store';
import { Item, SSubmenu } from './style';

type IType = {
  name: string;
  icon?: ReactElement<any, any>;
  defaultSub: number;
  sub: Submenu[];
  onClick?: (url: string) => any;
};

type Submenu = {
  name: string;
  url: string;
  icon?: ReactElement<any, any>;
  onClick?: (url: string) => any;
  permission?: EnumPermission;
  feature?: EnumFeatures;
};

const SubmenuItem: React.FC<IType> = ({ sub, name, icon, defaultSub, onClick }: IType) => {
  const { agency_features } = useFeature();
  const { permissions } = useSelector((state: IState) => state.permission);
  const current_url = useSelector<IState, string>(state => state.menu.current_url);
  const [opened, setOpened] = useState(() => sub.some(item => item.url === current_url));
  const history = useHistory();

  useEffect(() => {
    const any_sub_selected = sub.some(item => item.url === current_url);

    if (any_sub_selected) {
      setOpened(true);
    }
  }, [current_url, sub]);

  const arrow = useMemo(() => {
    if (opened) return <MdKeyboardArrowDown />;

    return <MdKeyboardArrowLeft />;
  }, [opened]);

  const sub_items = useMemo(() => {
    return sub
      .filter(item => {
        if (!item.permission) return item;

        return permissions.find(perm => perm === item.permission);
      })
      .filter(item => {
        if (!item.feature) return item;

        // @ts-ignore
        return agency_features[item.feature as string];
      });
  }, [sub, permissions, agency_features]);

  const defaultSubSelected = useMemo(() => {
    return sub_items[defaultSub] || sub_items[0];
  }, [defaultSub, sub_items]);

  function handleMain(e: any) {
    e.preventDefault();
    setOpened(val => !val);

    if (onClick && !opened) {
      onClick(defaultSubSelected.url);
      history.push(defaultSubSelected.url);
    }
  }

  const submenu = useMemo(() => {
    return sub_items.map(item => {
      const is_selected = item.url === current_url ? 'selected' : '';

      return (
        <li>
          <Link to={item.url} className={is_selected} onClick={() => item.onClick && item.onClick(item.url)}>
            {item.icon || <BsArrowRightShort />} {item.name}
          </Link>
        </li>
      );
    });
  }, [sub_items, current_url]);

  if (!sub_items.length) return null;

  return (
    <Item opened={opened}>
      <a className="menu-item-main" href={defaultSubSelected?.url} onClick={handleMain}>
        <div>
          {icon} {name}
        </div>{' '}
        {arrow}
      </a>

      <SSubmenu opened={opened}>{submenu}</SSubmenu>
    </Item>
  );
};

export default SubmenuItem;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '../../../components/Loader';
import AgencyService from '../../../services/Agency/AgencyService';
import { IState } from '../../../store';
import { setConfiguration } from '../../../store/modules/agency/actions';
import { IAgencyState } from '../../../store/modules/agency/reducer';
import { setGlobalLoading } from '../../../store/modules/auth/actions';

import { Container } from './styled';

type IProps = { children: React.ReactNode | React.ReactElement };

const AuthLayout: React.FC<IProps> = ({ children }: IProps) => {
  const dispatch = useDispatch();
  const global_loading = useSelector<IState, boolean>(state => state.auth.global_loading);
  const { configuration: config, info, last_time_updated } = useSelector<IState, IAgencyState>(state => state.agency);

  useEffect(() => {
    if (config && last_time_updated && last_time_updated > Date.now() + 24 * 60 * 60 * 1000) {
      dispatch(setGlobalLoading(false));
    } else {
      loadingConfiguration();
    }
  }, []);

  useEffect(() => {
    if (info && config) {
      const favicon = document.querySelector('head > link');

      document.title = info.name;

      if (favicon) {
        // @ts-ignore
        favicon.href = config.icon_image_url;
      }
    }
  }, [info, config]);

  async function loadingConfiguration() {
    const configuration = await AgencyService.getConfiguration();

    if (configuration) {
      document.title = configuration.info.name;

      dispatch(
        setConfiguration({
          ...configuration,
          last_time_updated: Date.now(),
        }),
      );
      dispatch(setGlobalLoading(false));
    }
  }

  return (
    <Container>
      <Loader loading={global_loading} />

      {children}
    </Container>
  );
};

export default AuthLayout;

import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import Menu from '../../../components/layout/Menu';
import Header from '../../../components/layout/Header';
import Image from '../../../components/layout/Image';
import { IState } from '../../../store';
import { IAgencyState } from '../../../store/modules/agency/reducer';

import { Container, Main, Menu as MenuStyled } from './styles';
import '../style.css';

type IProps = { children: React.ReactNode | React.ReactElement };

const StandardLayout: React.FC<IProps> = ({ children }: IProps) => {
  const { configuration, info } = useSelector<IState, IAgencyState>(state => state.agency);

  useEffect(() => {
    if (info && configuration && document.title.length === 0) {
      document.title = info.name;

      const favicon = document.querySelector('head > link');

      if (favicon) {
        // @ts-ignore
        favicon.href = configuration.icon_image_url;
      }
    }
  }, [info, configuration]);

  return (
    <Container>
      <MenuStyled>
        <Image />

        <Menu />
      </MenuStyled>
      <Main>
        <Header />
        {children}
      </Main>
    </Container>
  );
};

export default StandardLayout;

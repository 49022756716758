import React, { lazy } from 'react';

import { EnumPermission } from '../types/enum/EnumPermission';
import Route from './Route';

const AccountHolderRoutes: React.FC = () => {
  return (
    <>
      <Route path="/dashboard" exact component={lazy(() => import('../pages/Dashboard/AccountHolder'))} isPrivate />
      <Route
        path="/transactions"
        exact
        component={lazy(() => import('../pages/Transaction/List'))}
        permission={EnumPermission.VIEW_TRANSACTION}
        isPrivate
      />
      <Route
        path="/transfer"
        exact
        component={lazy(() => import('../pages/Transfer'))}
        permission={EnumPermission.CREATE_TED_P2P_TRANSFER}
        isPrivate
      />
      <Route path="/bankslip" exact component={lazy(() => import('../pages/Bankslip/List'))} isPrivate />
      <Route
        path="/bankslip/payment"
        exact
        component={lazy(() => import('../pages/Bankslip/Pay'))}
        permission={EnumPermission.CREATE_BANKSLIP_PAYMENT}
        isPrivate
      />
      <Route path="/card" exact component={lazy(() => import('../pages/Card'))} isPrivate />
      <Route path="/pix" exact component={lazy(() => import('../pages/Pix'))} isPrivate />
      <Route path="/pix/keys" exact component={lazy(() => import('../pages/Pix/Keys'))} permission={EnumPermission.MANAGE_PIX_KEYS} isPrivate />
      <Route
        path="/pix/transfer"
        exact
        component={lazy(() => import('../pages/Pix/Transfer'))}
        permission={EnumPermission.CREATE_PIX_TRANSFER}
        isPrivate
      />
      <Route
        path="/payment_link_create"
        exact
        component={lazy(() => import('../pages/PaymentLink/Create'))}
        permission={EnumPermission.MANAGE_PAYMENT_LINK}
        isPrivate
      />
      <Route
        path="/payment_link/:id"
        exact
        component={lazy(() => import('../pages/PaymentLink/Info'))}
        permission={EnumPermission.MANAGE_PAYMENT_LINK}
        isPrivate
      />
      <Route
        path="/payment_link"
        exact
        component={lazy(() => import('../pages/PaymentLink/List'))}
        permission={EnumPermission.MANAGE_PAYMENT_LINK}
        isPrivate
      />
      <Route
        path="/user_guest_account"
        exact
        component={lazy(() => import('../pages/UserGuestAccount/List'))}
        permission={EnumPermission.CREATE_GUEST_ACCOUNT_USER}
        isPrivate
      />
      <Route path="/config" exact component={lazy(() => import('../pages/Config'))} isPrivate />

      <Route
        path="/phone-recharge"
        exact
        component={lazy(() => import('../pages/PhoneRecharge'))}
        permission={EnumPermission.USE_DIGITAL_SERVICES}
        isPrivate
      />
    </>
  );
};

export default AccountHolderRoutes;

import { Balance, BalanceInfo } from '../../types/Balance';
import { fetchApi } from '../api';

class BalanceService {
  getBalance(): Promise<Balance | undefined> {
    return fetchApi({
      method: 'get',
      url: '/balance',
    });
  }
}

export default new BalanceService();

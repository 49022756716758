import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;

  ${state =>
    state.theme.background_image_url
      ? `background-image: url(${state.theme.background_image_url});`
      : `background-color: ${state.theme.background_color};`}
`;

export const Menu = styled.nav`
  width: 320px;
  padding: 0 28px;
`;
export const Main = styled.section`
  min-height: 100vh;
  width: 100%;
  padding: 0 30px 30px;
  height: fit-content;
`;

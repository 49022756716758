import AccountFee from '../../../types/AccountFee';
import { ActionReturn } from '../rootReducer';

export enum EnumAccountActions {
  SET_ACCOUNT_FEES = 'SET_ACCOUNT_FEES',
}

export function setAccountFees(data: AccountFee[]): ActionReturn {
  return {
    type: EnumAccountActions.SET_ACCOUNT_FEES,
    payload: data,
  };
}
